export function Loader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "50%",
        margin: "auto",
        height: "160px",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="200"
        className="lds-blank"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 100 100"
        style={{ background: "none" }}
      >
        <circle
          cx="50"
          cy="50"
          r="46"
          fill="none"
          stroke="#e8443b"
          strokeWidth="5"
        >
          <animate
            attributeName="stroke-dasharray"
            begin="-3.3s"
            calcMode="linear"
            dur="3.3"
            keyTimes="0;0.2;0.4;0.6;1"
            repeatCount="indefinite"
            values="0 0 0 144.51326206513048 0 144.51326206513048;0 0 144.51326206513048 0 0 144.51326206513048;0 0 144.51326206513048 0 0 144.51326206513048;0 144.51326206513048 0 144.51326206513048 0 144.51326206513048;0 144.51326206513048 0 144.51326206513048 0 144.51326206513048"
          ></animate>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="40"
          fill="none"
          stroke="#1aafd0"
          strokeWidth="5"
        >
          <animate
            attributeName="stroke-dasharray"
            begin="-3.0359999999999996s"
            calcMode="linear"
            dur="3.3"
            keyTimes="0;0.2;0.4;0.6;1"
            repeatCount="indefinite"
            values="0 0 0 125.66370614359172 0 125.66370614359172;0 0 125.66370614359172 0 0 125.66370614359172;0 0 125.66370614359172 0 0 125.66370614359172;0 125.66370614359172 0 125.66370614359172 0 125.66370614359172;0 125.66370614359172 0 125.66370614359172 0 125.66370614359172"
          ></animate>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="34"
          fill="none"
          stroke="#7450a0"
          strokeWidth="5"
        >
          <animate
            attributeName="stroke-dasharray"
            begin="-2.772s"
            calcMode="linear"
            dur="3.3"
            keyTimes="0;0.2;0.4;0.6;1"
            repeatCount="indefinite"
            values="0 0 0 106.81415022205297 0 106.81415022205297;0 0 106.81415022205297 0 0 106.81415022205297;0 0 106.81415022205297 0 0 106.81415022205297;0 106.81415022205297 0 106.81415022205297 0 106.81415022205297;0 106.81415022205297 0 106.81415022205297 0 106.81415022205297"
          ></animate>
        </circle>
        <g fill="none" strokeWidth="5" transform="rotate(180 50 50)">
          <circle cx="50" cy="50" r="46" stroke="#e8443b">
            <animate
              attributeName="stroke-dasharray"
              begin="-1.4519999999999997s"
              calcMode="linear"
              dur="3.3"
              keyTimes="0;0.2;0.4;0.6;1"
              repeatCount="indefinite"
              values="0 0 0 144.51326206513048 0 144.51326206513048;0 0 144.51326206513048 0 0 144.51326206513048;0 0 144.51326206513048 0 0 144.51326206513048;0 144.51326206513048 0 144.51326206513048 0 144.51326206513048;0 144.51326206513048 0 144.51326206513048 0 144.51326206513048"
            ></animate>
          </circle>
          <circle cx="50" cy="50" r="40" stroke="#1aafd0">
            <animate
              attributeName="stroke-dasharray"
              begin="-1.716s"
              calcMode="linear"
              dur="3.3"
              keyTimes="0;0.2;0.4;0.6;1"
              repeatCount="indefinite"
              values="0 0 0 125.66370614359172 0 125.66370614359172;0 0 125.66370614359172 0 0 125.66370614359172;0 0 125.66370614359172 0 0 125.66370614359172;0 125.66370614359172 0 125.66370614359172 0 125.66370614359172;0 125.66370614359172 0 125.66370614359172 0 125.66370614359172"
            ></animate>
          </circle>
          <circle cx="50" cy="50" r="34" stroke="#7450a0">
            <animate
              attributeName="stroke-dasharray"
              begin="-2.112s"
              calcMode="linear"
              dur="3.3"
              keyTimes="0;0.2;0.4;0.6;1"
              repeatCount="indefinite"
              values="0 0 0 106.81415022205297 0 106.81415022205297;0 0 106.81415022205297 0 0 106.81415022205297;0 0 106.81415022205297 0 0 106.81415022205297;0 106.81415022205297 0 106.81415022205297 0 106.81415022205297;0 106.81415022205297 0 106.81415022205297 0 106.81415022205297"
            ></animate>
          </circle>
        </g>
      </svg>
    </div>
  );
}
