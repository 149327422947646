import { StepProps } from "../../../../_interfaces";
import { SurveyStep1 } from "./Step1";
import { SurveyStep2 } from "./Step2";
import { SurveyStep3 } from "./Step3";
import { SurveyStep4 } from "./Step4";
import { SurveyStep5 } from "./Step5";

export const ShowSurveySteps = ({ step, form, setStep }: StepProps) => {
  return (
    <div className="step-content">
      {(step === 0 || step === 1) && (
        <SurveyStep1 form={form} step={step} setStep={setStep} />
      )}
      {step === 2 && <SurveyStep2 form={form} step={step} setStep={setStep} />}
      {step === 3 && <SurveyStep3 form={form} step={step} setStep={setStep} />}
      {step === 4 && <SurveyStep4 form={form} step={step} setStep={setStep} />}
      {step === 5 && form.survey.isPublished && <SurveyStep5 form={form} step={step} setStep={setStep} />}
    </div>
  );
};
