import { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Field } from "../../_models";

export const RichTextEditor = ({
  height = 200,
  field,
  onChange,
}: {
  height?: number;
  field?: Field;
  onChange?: any;
}) => {
  const editorRef = useRef<any>(null);
  const [val, setVal] = useState(field ? field.value : "");
  const change = () => {
    if (onChange && field) onChange(field.name, editorRef.current.getContent());
    setVal(editorRef.current.getContent());
  };

  return (
    <Editor
      onEditorChange={() => change()}
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={val}
      init={{
        height,
        menubar: true,
        language: "fr_FR",
        language_url: "/langs/fr_FR.js",
        paste_data_images: true,
        relative_urls: false,
        images_upload_url: "/backoffice/questionnaires/Public/js/survey/upload.js",
        automatic_uploads: false,
        file_picker_types: 'image',
        // images_upload_handler: async function(blobInfo, progress): Promise<string>{
          
        //   // let imageUrl = "";
        //   // setTimeout(function () {
        //   //   /* no matter what you upload, we will turn it into TinyMCE logo :)*/
        //   //   imageUrl = 'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png';
            
        //   // }, 10);
        //   return 'http://moxiecode.cachefly.net/tinymce/v9/images/logo.png';
        // },
        style_formats: [],
        plugins: [
          //"advlist autolink lists link image",
          "advlist autolink lists",
          "fullscreen emoticons",
          " paste",
        ],
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | ", 
          // +  "emoticons",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );
};
