import { immerable } from "immer";
import { eventKeys, renderKeys, transformKeys } from "../../../_constants";
import { Survey } from "../../../_entities";
import { db, http, tableRenderer, util } from "../../../_helpers";
import { TableModel } from "../../table";
import axios from "axios";
import xlsx from "json-as-xlsx";

const { RENDER_ACTIONS, RENDER_ACTIVE } = renderKeys;
const { TRANSFORM_SURVEY_STATUS } = transformKeys;
const { TABLE_DATA_CHANGE } = eventKeys;

export class SurveyTableModel extends TableModel {
  [immerable] = true;

  private static _instance: SurveyTableModel | undefined;
  private static _loadingResponses = false;

  private _allItems: Survey[];

  private static _loadingItems = false;

  constructor() {
    if (SurveyTableModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use SurveyTableModel.getInstance() instead of new."
      );
    }
    super();
    this._allItems = [];
    SurveyTableModel._instance = this;
  }

  public static getInstance(): SurveyTableModel {
    if (!SurveyTableModel._instance) {
      return new SurveyTableModel();
    }
    return SurveyTableModel._instance;
  }

  public reset(): SurveyTableModel {
    SurveyTableModel._instance = undefined;
    return SurveyTableModel.getInstance();
  }

  public get isDirty(): boolean {
    return Boolean(db.get("survey_list_dirty"));
  }

  public get drafts(): Survey[] {
    return this._allItems.filter((a) => a.status === "DRAFT");
  }

  public get awaitings(): Survey[] {
    return this._allItems.filter((a) => a.status === "AWAITING");
  }

  public get publisheds(): Survey[] {
    return this._allItems.filter((a) => a.status === "PUBLISHED");
  }

  public get expireds(): Survey[] {
    return this._allItems.filter((a) => a.status === "EXPIRED");
  }

  public get activeTab(): string {
    return db.get("survey_active_tab");
  }

  public getActiveTab(context: any): any {
    const tabs = this.getTabs(context);
    return tabs.find((t) => t.id === this.activeTab) ?? tabs[0];
  }

  public getColumns(context: any): any[] {
    return [
      /* {
              title: "#",
              data: "data.uuid",
              render: tableRenderer.renderData({ renderKey: RENDER_ID }),
            },*/
      // {
      //   title: "Type",
      //   data: "data.replyVisibility",
      //   render: tableRenderer.renderData({ renderKey: RENDER_SURVEY_TYPE }),
      // },
      { title: "Titre", data: "data.title" },
      {
        title: "Statut",
        data: "data.status",
        render: tableRenderer.renderData({
          transformKey: TRANSFORM_SURVEY_STATUS,
        }),
      },
      { title: "Date de création", data: "data.createdAt" },
      {
        title: "Ouvert aux réponses",
        data: "data.active",
        render: tableRenderer.renderData({ renderKey: RENDER_ACTIVE }),
      },
      {
        title: "Actions",
        data: "data.uuid",
        render: tableRenderer.renderData({
          renderKey: RENDER_ACTIONS,
          actions: (props: any) => this.getActions({ ...context, ...props }),
        }),
      },
    ];
  }

  getActions(context: any) {
    const {
      row: { data },
      tabId,
    } = context;
    const path = `/backoffice/questionnaires`;
    const editLink = `${path}/edit?uuid=${data.uuid}`;
    //const deleteLink = `${path}/delete?uuid=${data.uuid}`;

    const secondaryActions: any[] = [];

    const detailAction = {
      label: "Détails",
      attributtes: {
        className: "btn btn-info",
        "data-eventkey": "a.onshow",
        href: `/backoffice/questionnaires/show?uuid=${data.uuid}`,
      },
    };
    const editAction = {
      label: "Modifier",
      attributtes: {
        className: "onedit",
        "data-eventkey": "a.onedit",
        href: editLink,
      },
    };
    const draftAction = {
      label: "Repasser à brouillon",
      attributtes: {
        className: "ondraft",
        "data-eventkey": "a.ondraft",
        href: editLink,
      },
    };
    const awaitingAction = {
      label: "Soumettre pour validation",
      attributtes: {
        className: "onawaiting",
        "data-eventkey": "a.onawaiting",
        href: editLink,
      },
    };
    const publishAction = {
      label: "Publier",
      attributtes: {
        className: "onpublish",
        "data-eventkey": "a.onpublish",
        href: editLink,
      },
    };
    const qrcodeAction = {
      label: "Générer un QR Code",
      attributtes: {
        className: "onqrcode",
        "data-eventkey": "a.onqrcode",
        href: editLink,
      },
    };
    const exportAction = {
      label: "Exporter les résultats au format XLSX",
      attributtes: {
        className: "onexport",
        "data-eventkey": "a.onexport",
        href: editLink,
      },
    };

    const testAction = {
      label: "Tester le questionnaire",
      attributtes: {
        className: "ontest",
        href: util.getConfigs().surveyReplyTestingsurvey + context.row.data.uuid,
        target: "_blank",
      },
    };
    const previewAction = {
      label: "Prévisualiser le questionnaire",
      attributtes: {
        className: "onpreview",
        href: util.getConfigs().surveyPreviewTarget + context.row.data.uuid,
        target: "_blank",
      },
    };
    const expiredAction = {
      label: "Marquer comme expiré",
      attributtes: {
        className: "onexpired",
        "data-eventkey": "a.onexpired",
        href: editLink,
      },
    };
    const activateAction = {
      label: "Activer le questionnaire",
      attributtes: {
        className: "onactivate",
        "data-eventkey": "a.onactivate",
        href: editLink,
      },
    };
    const deActivateAction = {
      label: "Désactiver le questionnaire",
      attributtes: {
        className: "ondeactivate",
        "data-eventkey": "a.ondeactivate",
        href: editLink,
      },
    };
    let primaryAction: any = null;

    const deleteAction = {
      label: "Supprimer",
      attributtes: {
        className: "ondelete",
        "data-eventkey": "a.ondelete",
        href: editLink,
      },
    };
    secondaryActions.push(previewAction);

    if (tabId === "awaiting" || data.isAwaiting) {
      secondaryActions.push(publishAction);
      secondaryActions.push(draftAction);
    }
    if (tabId !== "draft" && !data.isDraft) {
        secondaryActions.push(testAction);
        if ((tabId === "published" || data.isPublished) && !data.active){
            const position = secondaryActions.indexOf(testAction);
            position !== -1 && secondaryActions.splice(position, 1);
        }
      //secondaryActions.push(draftAction);
      //secondaryActions.push(detailAction);
      //primaryAction = detailAction;
    } else {
      secondaryActions.push(editAction);
      secondaryActions.push(awaitingAction);
    }
    if (tabId === "expired" || data.isExpired) {
      //secondaryActions.push(expiredAction);
      secondaryActions.length = 0;
      secondaryActions.push(previewAction)
      secondaryActions.push(exportAction)
      secondaryActions.push(deleteAction);
    }
    if (tabId === "published" || data.isPublished) {
      //if (data.active) secondaryActions.push(editAction);
      if (data.active) secondaryActions.push(qrcodeAction);
      if (data.active) secondaryActions.push(deActivateAction);
      if (!data.active) secondaryActions.push(activateAction);
      if (data.active) secondaryActions.push(exportAction);
      if (data.active || !data.active) secondaryActions.push(expiredAction);
    }

    if (tabId === "draft" || data.isDraft) {
      secondaryActions.push(deleteAction);
    }

    return {
      primaryAction: detailAction,
      secondaryActions,
    };
  }

  public getTabs(context: any): any[] {
    return [
      {
        id: "all",
        label: "Tous les questionnaires",
        data: () => this.getData("ALL"),
        tableSettings: {
          ajax: (d: any, cb: any) => this.getData("ALL", d, cb),
          columns: this.getColumns({ ...context, tabId: "all" }),
        },
      },
      {
        id: "published",
        label: "Publié(s)",
        data: () => this.getData("PUBLISHED"),
        tableSettings: {
          ajax: (d: any, cb: any) => this.getData("PUBLISHED", d, cb),
          columns: this.getColumns({ ...context, tabId: "published" }),
        },
      },
      {
        id: "draft",
        label: "Brouillon(s)",
        data: () => this.getData("DRAFT"),
        tableSettings: {
          ajax: (d: any, cb: any) => this.getData("DRAFT", d, cb),
          columns: this.getColumns({ ...context, tabId: "draft" }),
        },
      },
      {
        id: "awaiting",
        label: "En attente",
        data: () => this.getData("AWAITING"),
        tableSettings: {
          ajax: (d: any, cb: any) => this.getData("AWAITING", d, cb),
          columns: this.getColumns({ ...context, tabId: "awaiting" }),
        },
      },
      {
        id: "expired",
        label: "Expiré(s)",
        data: () => this.getData("EXPIRED"),
        tableSettings: {
          ajax: (d: any, cb: any) => this.getData("EXPIRED", d, cb),
          columns: this.getColumns({ ...context, tabId: "expired" }),
        },
      },
    ];
  }

  getSurveyByStatus(status: string) {
    if (status === "DRAFT") return this.drafts;
    if (status === "EXPIRED") return this.expireds;
    if (status === "PUBLISHED") return this.publisheds;
    if (status === "AWAITING") return this.awaitings;

    return this._allItems;
  }

  async getData(status: string, d?: any, cb?: any) {
    /*if (SurveyTableModel._loadingItems) {
      return [];
    }*/
    SurveyTableModel._loadingItems = true;
    if (!this._allItems.length || this.isDirty) {
      const res = await this.get(`/surveys`);
      if (Array.isArray(res.data)) {
        let datas = res.data;
        // datas.map(async (item, index) => {
        //   let endDate = new Date(item.endDate)
        //   if(item.status !== "Expired"){
        //     if(endDate) {
        //       if(endDate < new Date()){
        //         await this.switchStatusWithoutLoading(item, 'Expired');
        //         console.log(item.status)
        //       }
        //     }
        //   }
        //   // console.log(item)
        // })
        // console.log("--------- Done sorting ---------")
        this._allItems = Survey.fromJsonArray(datas);
        db.remove("survey_list_dirty");
      }
    }
    SurveyTableModel._loadingItems = false;
    const data = this.getSurveyByStatus(status);
    if (!d) {
      return data.map((s) => s.toRow());
    }

    cb({
      ...d,
      recordsTotal: this._allItems.length,
      recordsFiltered: data.length,
      // data: data.slice(d.start, d.length - 1).map((s) => s.toRow()),
    });
    console.log(cb)
  }

  async onDraft(data: any) {
    await this.switchStatus(data, "DRAFT");
  }

  async onPublish(data: any) {
    await this.switchStatus(data, "PUBLISHED");
  }

  async onQrcode(data: any, show = true, ress = {}) {
    if (show) util.showLoading();
    let str = "";
    const configs = util.getConfigs();
    let res: any = ress;
    if (!res?.data?.id) res = await this.get(`/surveys/${data?.data?.data.uuid}`);
    if (Array.isArray(res.data?.metas)) {
      const m = res.data?.metas.find((n) => n.code === configs.qrCodeKey);
      if (m) {
        str = m.value;
      }
    }

    if (!str.length) {
      try {
        let token = localStorage.getItem("jwttoken") || "";

        let header = {
          Authorization: "Bearer " + token,
        };

        let param = {
          method: "POST",
          url: configs.urlShortCode,
          headers: header,
          data: {
            state: true,
            evenement: "SURVEY",
            target: configs.surveyReplyTarget + data?.data?.data.uuid,
            startAt: new Date(),
            expireAt: new Date(
              new Date().getFullYear() + 100,
              new Date().getMonth(),
              new Date().getDate()
            ),
          },
        };

        let response = await axios.request(param);
        // console.log("-----------------", response.data);
        let short = response.data?.url.urlShort;

        const resQr = await http.post("/qrcode", {
          data: {
            url: configs.qrcodeLink + short,
          },
        });

        let json = JSON.parse(resQr);

        if (json.qrcodeImage) {
          str = json.qrcodeImage;
        }
        const rr = await this.post({
          path: `/surveys/${data?.data?.data.uuid}/metas`,
          data: [
            {
              action: "add",
              metas: [
                {
                  code: configs.shortCodeKey,
                  value: short,
                },
                {
                  code: configs.qrCodeKey,
                  value: str,
                },
              ],
            },
          ],
        });
        if (rr.data) {
          res.data.metas.push({
            code: configs.shortCodeKey,
            value: short,
          });
          res.data.metas.push({
            code: configs.qrCodeKey,
            value: str,
          });
        }
      } catch (error) { }
    }
    if (show) util.hideLoading();
    if (str.length && show) {
      util.showQRCode(str);
    }
    return res;
  }

  async getResponses(uuid: string | null) {
    if (SurveyTableModel._loadingResponses) {
      return [] as any[];
    }
    SurveyTableModel._loadingResponses = true;
    return new Promise<any>(async (resolve) => {
      const res = await this.get(`/survey_replies/by_survey_2/${uuid}`);
      SurveyTableModel._loadingResponses = false;
      resolve(res);
    });
  }

  async onExport(data: any) {
    try {
      util.showLoading("Génération de votre document ... ");

      let response = await this.getResponses(data?.data?.data.uuid);

      let questions: any[] = [];
      response.data.forEach((element) => {
        if (element.answers.length > questions.length) {
          questions = element.answers;
        }
      });

      const getIndex = (row) => {
        const i = response.data.findIndex((r: { id: any }) => r.id === row.id);
        return i + 1;
      };

      const getDataByKey = (row, key: string) => {
        const i = row.data.findIndex(
          (r: { label: string; value: any }) =>
            r.label?.toLocaleLowerCase() === key.toLocaleLowerCase()
        );
        if (i > -1) {
          return row.data[i].value;
        }
        return "";
      };

      const isSurveyReplyTest = (data: any[]) => {

        return data.length > 0 && data[0].label == 'test';

      }

      let finalColum: any = [];
      /*finalColum.push({
        label: "",
        value: (row: { createdAt: string }) => {
          return "Reponse " + "#" + getIndex(row) + " reçu le " + row.createdAt;
        },
      });*/
      finalColum.push({
        label: "Numéro de la campagne",
        value: (qd: any) => {
          return data?.data?.data?.id;
        },
      });
      finalColum.push({
        label: "Titre de la campagne",
        value: (qd: any) => {
          // console.log("DATA FROM SURVEY REPLIES: ", qd)
          return `${data?.data?.data?.title}${isSurveyReplyTest(qd.data) ? " - [TEST]" : ''}`;
        },
      });
      finalColum.push({
        label: "Accepter les CGU",
        value: (qd: any) => {
          return qd.cgu ? "Oui" : "Non";
        },
      });
      finalColum.push({
        label: "Accepter la politique de protection des données",
        value: (qd: any) => {
          return qd.rgpd ? "Oui" : "Non";
        },
      });
      finalColum.push({
        label: "Ce questionnaire est-il terminé ?",
        value: (qd: any) => {
          return qd.finished ? "Oui" : "Non";
        },
      });

      finalColum.push({
        label: "N° Questionnaire",
        value: (qd: any) => {
          return data?.data?.data.id;
        },
      });
      finalColum.push({
        label: "Lot de réponses",
        value: (qd: any) => {
          return getIndex(qd);
        },
      });
      finalColum.push({
        label: "Date",
        value: (qd: any) => {
          return qd.createdAt;
        },
      });
      finalColum.push({
        label: "N° Consommateur",
        value: (qd: any) => {
          return getDataByKey(qd, "num_conso");
        },
      });
      finalColum.push({
        label: "N° Carte",
        value: (qd: any) => {
          return getDataByKey(qd, "num carte");
        },
      });
      finalColum.push({
        label: "Email",
        value: (qd: any) => {
          return getDataByKey(qd, "email");
        },
      });
      finalColum.push({
        label: "Téléphone",
        value: (qd: any) => {
          return getDataByKey(qd, "mobile");
        },
      });

      questions.forEach((element) => {
        finalColum.push({
          label: element.questionName,
          value: (row) => {
            return (
              row.answers.find((i) => i.questionId === element.questionId)
                ?.value ?? ""
            );
          },
        });
      });

      let dataToImport = [
        {
          sheet: "Export des reponses",
          columns: finalColum,
          content: response.data,
        },
      ];

      let settings = {
        fileName:
          "Export_" +
          data?.data?.data.title.toLowerCase().replaceAll(" ", "_") +
          "-" +
          new Date().getTime(),
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: false,
      };

      xlsx(dataToImport, settings);

      util.hideLoading();
    } catch (err) {
      console.error(err);
      util.hideLoading();
      util.showError(
        "Error",
        "Erreur lors de la génération veuillez réessayer"
      );
    }
  }

  async onAwaiting(data: any) {
    await this.switchStatus(data, "AWAITING");
  }
    /*--- Confirmation button mark expired ---*/
  async onExpired(data: any) {
      //await this.switchStatus(data, "EXPIRED");
    util.showConfirm(
      `Voulez vous marquer le questionnaire: ${data?.data?.data?.title} comme expiré?`,
      async () => {
        await this.switchStatus(data, "EXPIRED");
      }
    );
  }/*--- End Confirmation button mark expired ---*/

  async onActivate(data: any) {
    await this.switchStatus(data, "ACTIVATE");
  }

  async onDeActivate(data: any) {
    await this.switchStatus(data, "DEACTIVATE");
  }

  async switchStatus(data: any, status: string) {
    util.showLoading();
    const res = await this.put({
      path: `/surveys/${data?.data?.data?.uuid}`,
      data: { status },
    });
    if (res?.data?.status) {
      db.set("survey_list_dirty", true);
      util.emitter.emit(TABLE_DATA_CHANGE);
    }

    util.hideLoading();

    window.location.reload();
  }

  async switchStatusWithoutLoading(data: any, status: string) {
    // util.showLoading();
    const res = await this.put({
      path: `/surveys/${data?.data?.data?.uuid}`,
      data: { status },
    });
    if (res?.data?.status) {
      db.set("survey_list_dirty", true);
      util.emitter.emit(TABLE_DATA_CHANGE);
    }

    // util.hideLoading();

    // window.location.reload();
  }


  async onDelete(data: any) {

    util.showConfirm(
      `Voulez vous supprimer le questionnaire: ${data?.data?.data?.title}`,
      async () => {

        util.showLoading();
        const res = await this.remove(`/surveys/${data?.data?.data?.uuid}`);
        if (res?.statusCode == 200) {
          window.location.reload();
        }
        else {
          util.hideLoading();
          util.showError("Suppression", "Le questionnaire n'a pas pu être supprimé!!!");

        }

      }
    );

  }
}
