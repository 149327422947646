import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFieldUpdatePayload, IMenu } from "../_interfaces";
import { BaseModel, BusinessModel, FormModel } from "../_models";

const initialState = BaseModel.getInstance();

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    reset: (state) => {
      state = state.reset();
    },
    replace: (state, action: PayloadAction<BaseModel>) => {
      state = action.payload.clone();
    },
    updateMenus: (state, action: PayloadAction<IMenu[]>) => {
      state.ui.updateMenus(action.payload);
    },
    updateFieldValue: (state, action: PayloadAction<IFieldUpdatePayload>) => {
      state.page.model?.form.updateFieldValue(action.payload);
    },
    replaceForm: (state, action: PayloadAction<FormModel>) => {
      state.page.model!.form = action.payload;
    },
    replaceModel: (state, action: PayloadAction<BusinessModel>) => {
      state.page.model = action.payload;
    },
  },
});

export const {
  reset,
  replace,
  updateMenus,
  updateFieldValue,
  replaceForm,
  replaceModel,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
