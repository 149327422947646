import { All } from "./All";
import { Awaiting } from "./Awaiting";
import { Draft } from "./Draft";
import { Expired } from "./Expired";
import { Maquettes } from "./Maquettes";
import { PublishedSurveys } from "./Published";

export const SurveyTabs = ({ tab }) => {
  return (
    <>
      {tab.id === "all" && <All tab={tab} />}
      {tab.id === "published" && <PublishedSurveys tab={tab} />}
      {tab.id === "draft" && <Draft tab={tab} />}
      {tab.id === "awaiting" && <Awaiting tab={tab} />}
      {tab.id === "expired" && <Expired tab={tab} />}
      {tab.id === "maquettes" && <Maquettes tab={tab} />}
    </>
  );
};
