import { Location } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import mitt, { Emitter } from "mitt";
import { format } from "date-fns";
import {
  appConstants,
  DEFAULT_MESSAGE,
  localErrors,
  pageConfigs,
  transformKeys,
} from "../_constants";
import { ws } from "./window";
import { RootModel, SurveyFormModel } from "../_models";
import { http } from "./http";

const { TRANSFORM_SURVEY_STATUS } = transformKeys;
const { ENV, defaultTitle, surveyStatuses } = appConstants;

const emitter: Emitter = mitt();

const getSurveyStatus = (value: string) => {
  const status = surveyStatuses.find((s) => s.id === value);
  if (status) {
    return status.label;
  }
  return value;
};

const transformers = [
  {
    key: TRANSFORM_SURVEY_STATUS,
    transform: getSurveyStatus,
  },
];

const checkEnv = (env: string) => {
  return ENV === env;
};

const isDev = () => {
  return checkEnv("dev");
};

const showError = (
  title: string = "Erreur!",
  message: string = "Une erreur est survenue"
) => {
  if (ws.AVOMARK && ws.AVOMARK.ModalDanger) {
    ws.AVOMARK.ModalDanger(title, message);
  }
};

const hideLoading = () => {
  if (typeof ws.hideLoading === "function") {
    ws.hideLoading();
  }
};

const showLoading = (msg: string = DEFAULT_MESSAGE) => {
  if (typeof ws.showLoading === "function") {
    ws.showLoading(msg);
  }
};

const toString = (data: any) => {
  return typeof data === "string" ? data : JSON.stringify(data);
};

const string = (data: any) => {
  if (data) {
    return data;
  }
  return "-";
};

const goTo = (url: string) => {
  ws.location.assign(url);
};
const getErrorByKey = (key: string) => {
  const err = localErrors.find((e) => e.key === key);
  return err?.value || key;
};

const reload = () => {
  ws.location.reload();
};

const path = () => {
  return ws.location.pathname;
};

const sleep = async (timeout: number) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, timeout)
  );
};

const uploadSurveyLogoToApi = async (file, uuid) => {
  if (!file) {
    console.error('Aucun fichier sélectionné');
    return;
  }
  const formData = new FormData();
  formData.append('logo', file);
  formData.append('uuid', uuid)
  var apiSurveyLogoUrl = getConfigs().surveyUploadLogo + uuid;
  // var url = 'backoffice/api/PostLogo';
  const response = await fetch(apiSurveyLogoUrl, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
      headers:{
        'Authorization': 'Bearer ' + getConfigs().surveyApiToken
      }
    })
    .then(response => {
      if (!response.ok) {
          throw new Error(`Erreur HTTP! Statut: ${response.status}`);
      }
      return response.text();
    })
    .then(data => {
      var url = `${data}`;
      return url;
    })
    .catch(error => {
      console.error('Erreur lors de l\'envoi du fichier :', error);
      return "";
    });

    return response;
  }

const upload = async (file: any, uuid: string) => {
  // const filePath = "images/logos/" + file.name;
  // const d = await ws.s3upload(file, filePath);
  uuid = window.location.search?.split("=")[1];
  const d = await uploadSurveyLogoToApi(file, uuid);
  // const path = getConfigs().surveyUploadLogo + uuid;
  // const formData = new FormData();
  // formData.append('logo', file);
  // formData.append('uuid', uuid);
  // formData.append('path', path);
  // debugger;
  // const res = await SurveyFormModel.getInstance().postSurveyLogo(formData)
  // debugger;
  // return res;
  return d;
  // if (d && d.Location) {
  //   return d.Location;
  // }
  // return "";
};

const getTitle = (params: Location) => {
  let title = defaultTitle;
  const config = getPageConfigs(params.pathname);
  if (config) title = config.pageTitle;
  return title;
};

const getPageConfigs = (pth: string = path()) => {
  return pageConfigs.find((p) => p.path === pth);
};

const transformRequest = (jsonData: any = {}) =>
  Object.entries(jsonData)
    .map((x: any) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join("&");

const formatDate = (fmt: string, dt: string) => {
  if (dt) return format(new Date(dt), fmt);
  return "-";
};

const formatDouble = (num: any) => "-";

const getTransformer = (key: string) => {
  return undefined;
};
const transformValue = (transformKey: string, value: any) => {
  const i = transformers.findIndex((t) => t.key === transformKey);
  if (i > -1) {
    return transformers[i].transform(value);
  }
};

const showConfirm = (msg: string, cb: any) => {
  if (ws.bootbox) {
    ws.bootbox.confirm({
      title: '<p style="text-align: left;">Attention!<p>',
      message: msg,
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Annuler',
        },
        confirm: {
          label: '<i class="fa fa-check"></i> Confirmer',
        },
      },
      callback: function (result) {
        if (result) {
          cb();
        }
      },
    });
  } else {
    showError("Erreur! ", "Impossible d'afficher le QR Code");
  }
};

const showQRCode = (code: string) => {
  if (ws.bootbox && code) {
    const img = `<img id="base64ImageForDisplay" alt="Preview Image" src="data:image/png;base64,${code}">`;
    const html = `<figure style="display: flex;justify-content: center;">${img}</figure>`;
    ws.bootbox.confirm({
      title: '<p style="text-align: center;">Votre QR Code est prêt!<p>',
      message: html,
      buttons: {
        cancel: {
          label: '<i class="fa fa-times"></i> Annuler',
        },
        confirm: {
          label: '<i class="fa fa-print"></i> Imprimer',
        },
      },
      callback: function (result) {
        if (result) {
          const myWindow = window.open("", "Image");
          if (myWindow) {
            myWindow.document.write(img);
            myWindow.print();
          }
        }
      },
    });
  } else {
    showError("Erreur! ", "Impossible d'afficher le QR Code");
  }
};

const render = (component: any) => {
  return ReactDOMServer.renderToString(component);
};

function check_url_link(url: string) {
  let regexLink =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

  if (!regexLink.test(url) || !url.startsWith("http")) {
    return false;
  } else return true;
}

// function check_url_before_send(data: object) {
//   let case_check = [
//     "fidelityRegistrationUrl",
//     //"unsubscribeUrl",
//     "cguUrl",
//     "rgpdUrl",
//   ];
//   let status = true;


//   for (let element of Object.keys(data)) {
//     if (case_check.includes(element) && data[element]["error"] !== "") {
//       data[element]["error"]
//       status = false;
//       console.log("triuve ", element, "  value elm ", data[element]["error"]);

//       break;
//     }
//   }

//   return status;
// }

function showToast(message: string, time?: number) {
  // Get the snackbar DIV
  var x: any = document.getElementById("snackbar");

  // Add the "show" class to DIV

  // x.removeChild(x.firstChild);
  x.innerHTML = "";
  x.append(message);
  x.className = "show";

  // After 3 seconds, remove the show class from DIV
  setTimeout(
    function () {
      x.className = x.className.replace("show", "");
    },
    time ? time : 3000
  );
}

const isProd = () => {
  return (
    window.location.href.indexOf("survey360.avomark.fr") !== -1 &&
    window.location.href.indexOf("staging") === -1
  );
};

const isStaging = () => {
  return window.location.href.indexOf("staging") !== -1;
};

const getConfigs = () => {
  let configs: any = appConstants.configsAll;

  if (isProd()) {
    configs = {
      ...configs,
      ...appConstants.configsProd,
    };
  } else if (isStaging()) {
    configs = {
      ...configs,
      ...appConstants.configsStaging,
    };
  } else {
    configs = {
      ...configs,
      ...appConstants.configsLocal,
    };
  }
  return configs;
};

export const util = {
  getConfigs,
  checkEnv,
  isProd,
  isDev,
  isStaging,
  toString,
  transformRequest,
  goTo,
  string,
  hideLoading,
  showLoading,
  reload,
  getTitle,
  getPageConfigs,
  path,
  getErrorByKey,
  showError,
  sleep,
  upload,
  formatDate,
  formatDouble,
  render,
  transformValue,
  emitter,
  showQRCode,
  showConfirm,
  check_url_link,
  //check_url_before_send,
  showToast,
};
