import { immerable } from "immer";
import { cloneDeep } from "lodash";
import { appConstants } from "../../../_constants";
import { QuestionValidator } from "../../../_entities";
import { IFieldUpdatePayload } from "../../../_interfaces";
import { Field } from "../../field";
import { FormModel } from "../../form";

const { validatorTypes } = appConstants;
export class ValidatorFormModel extends FormModel {
  [immerable] = true;

  min: Field;
  max: Field;
  type: Field;
  validator: QuestionValidator;

  constructor(options?: { [key: string]: any }) {
    super();
    this.validator = options?.validator || new QuestionValidator();
    this.initFields();
  }

  public get isRange(): boolean {
    return this.type.value.indexOf("RANGE") !== -1;
  }

  enableFields() {
    //this.min.enable = this.isRange;
    //this.max.enable = this.isRange;
  }
  setTypes() {
    const i = validatorTypes.findIndex((v) => v.value === this.type.value);
    if (i != 0) {
      this.min = new Field({
        name: "min",
        label: "Minimum",
        type: "number",
        helpText: "Valeur minimale",
      });
      this.max = new Field({
        name: "max",
        type: "number",
        label: "Maximum",
        helpText: "Valeur maximale",
      });
    }
    // if (i > -1) {
    //   this.min.type = validatorTypes[i].inputType || this.min.type;
    //   this.max.type = validatorTypes[i].inputType || this.max.type;
    //   if (validatorTypes[i].step) {
    //     this.min.data.step = validatorTypes[i].step;
    //   }
    // }
  }

  initFields() {
    this.min = new Field({
      name: "min",
      label: "Minimum",
      helpText: "Nombre de charactères minimum",
    });
    this.max = new Field({
      name: "max",
      label: "Maximum",
        helpText: "Nombre de charactères maximum",
    });
    this.type = new Field({
      name: "type",
      type: "select",
      label: "Type de validation",
      helpText: "Type de validation des données",
      value: "TEXT",
      options: validatorTypes,
    });

    this.populateFields();
    this.enableFields();
    this.setTypes();
  }

  populateFields() {
    if (!this.validator.id) return;
    this.min.value = this.validator.min;
    this.max.value = this.validator.max;
    this.type.value = this.validator.type;
  }

  updateFieldValue(payload: IFieldUpdatePayload) {
    const { key, value } = payload;
    this[key].value = value;
    this.enableFields();
    this.setTypes();
  }

  public clone() {
    return cloneDeep(this);
  }

  getBody() {
    return {
      id: this.validator.id || 0,
      type: this.type.value,
      min: this.min.value,
      max: this.max.value,
    };
  }
}
