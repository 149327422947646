import { util } from "../../../../_helpers";
import { StepProps } from "../../../../_interfaces";

export const SurveyStep3 = (props: StepProps) => {
  const { setStep, form } = props;
  // console.log(form.survey);
  return (
    <div className="step-pane active">
      <form>
        <div className="row">
          {form.survey.cgus.map((item, i) => (
            <div key={i} className={"form-group " + item.layout}>
              <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {item.label}
              </label>
              {item.value.indexOf("http") === -1 && (
                <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
              )}
              {item.value.indexOf("http") !== -1 && (
                <p>
                  <a
                    onClick={() => util.goTo(item.value)}
                    href={item.value}
                    style={{ cursor: "pointer" }}
                    className="btn"
                    target="_blank"
                  >
                    Ouvrir
                  </a>
                </p>
              )}
            </div>
          ))}
        </div>
        <hr />
        <a onClick={() => setStep(2)} className="btn info btn-next mr-2">
          <i className="fa fa-arrow-left"></i> Précédent
        </a>
        <a className="btn info btn-next" onClick={() => setStep(4)}>
          <i className="fa fa-arrow-right"></i> Suivant
        </a>
      </form>
    </div>
  );
};
