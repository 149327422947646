import { appConstants } from "../_constants";
import { http } from "../_helpers/http";
import { ApiPayload } from "../_interfaces";
import { IApiResponse } from "../_interfaces";

export abstract class RootModel {
  getResponse(res: any): IApiResponse {
    let result = "";
    if (typeof res === "string") {
      if (res.indexOf("|") !== -1) {
        let parts = res.split("|");
        result = parts[2];
      } else {
        result = res;
      }
    }
    try {
      return JSON.parse(result);
    } catch (error) {
      console.error(error);
    }

    return res;
  }

  appendVersion(payload: ApiPayload) {
    return { ...payload, path: appConstants.API_VERSION + payload.path };
  }

  async get(path: string): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.get(
        "/api?path=" + appConstants.API_VERSION + path
      );
      resolve(this.getResponse(res));
    });
  }

  async getSurveyLogo(path: string): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.get(
        "/api?path=" + appConstants.API_VERSION + path
      );
      resolve(this.getResponse(res));
    });
  }

  async post(payload: ApiPayload): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.post("/api", this.appendVersion(payload));
      resolve(this.getResponse(res));
    });
  }

  async postExternal({ path, data }: ApiPayload): Promise<IApiResponse> {
    return await http.postExternal(path, data);
  }

  async postSurveyLogo(data: FormData): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.post("/api/postLogo", data);
      resolve(this.getResponse(res));
    });
  }

  async put(payload: ApiPayload): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.put("/api", this.appendVersion(payload));
      resolve(this.getResponse(res));
    });
  }

  async remove(path: string): Promise<IApiResponse> {
    return new Promise(async (resolve) => {
      const res = await http.remove(
        "/api?path=" + appConstants.API_VERSION + path
      );
      resolve(this.getResponse(res));
    });
  }
}
