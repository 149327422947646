import { CellData } from "../_components";
import { eventKeys } from "../_constants";
import { util } from "./util";

declare var $: any;
const { TABLE_BTN_CLICK } = eventKeys;

class TableRenderer {
  private static _instance: TableRenderer | undefined;

  constructor() {
    if (TableRenderer._instance) {
      throw new Error(
        "Error : Instanciation failed : Use TableRenderer.getInstance() instead of new."
      );
    }
    TableRenderer._instance = this;
  }

  public static getInstance(): TableRenderer {
    if (!TableRenderer._instance) {
      return new TableRenderer();
    }
    return TableRenderer._instance;
  }

  public static reset() {
    TableRenderer._instance = undefined;
  }

  renderData(options?: any) {
    return (data: any, type: any, row: any, meta: any) => {
      if (type === "display") {
        return util.render(<CellData {...{ data, row, meta, options }} />);
      }
      return data;
    };
  }

  getOptions(props: any) {
    const opts = {
      pageLength: 10,
      ordering: false,
      retrieve: false,
      lengthMenu: [
        [10, 25, 50, 100, 500, -1],
        [10, 25, 50, 100, 500, "All"],
      ],
      language: {
        sSearch: "Rechercher",
        oPaginate: {
          sFirst: "Premier",
          sPrevious: "Précédent",
          sNext: "Suivant",
          sLast: "Dernier",
        },
        processing: "Traitement en cours...",
        search: "Rechercher&nbsp;:",
        lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
        info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
        infoEmpty:
          "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
        infoFiltered:
          "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
        infoPostFix: "",
        loadingRecords: "Chargement en cours...",
        zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher :(",
        emptyTable: "Aucune donnée disponible dans le tableau :(",
        paginate: {
          first: "Premier",
          previous: "Pr&eacute;c&eacute;dent",
          next: "Suivant",
          last: "Dernier",
        },
        aria: {
          sortAscending: ": activer pour trier la colonne par ordre croissant",
          sortDescending:
            ": activer pour trier la colonne par ordre décroissant",
        },
        buttons: {
          pageLength: {
            _: "Afficher %d éléments",
            "-1": "Montre tout",
          },
        },
      },
      stateSave: true,
      autoWidth: true,
      processing: false,
      serverSide: false,
      paging: true,
      destroy: true,
      searching: { regex: true },
      createdRow: function (row: any, data: any, index: any) {
        $(row).on("click", "a", (e: any) => {
          if ($(e.currentTarget).hasClass("dropdown-toggle")) {
            $(e.currentTarget).parent().toggleClass("open");
          } else if ($(e.currentTarget).hasClass("inactive")) {
            e.preventDefault();
            return;
          } else {
            const key = $(e.currentTarget).attr("data-eventkey");
            const href = $(e.currentTarget).attr("href");
            if (key) {
              e.preventDefault();
              util.emitter.emit(TABLE_BTN_CLICK, {
                row,
                data,
                index,
                key,
                href,
              });
            }
          }
        });
      },
    };
    return opts;
  }
}

export const tableRenderer = TableRenderer.getInstance();
