import { appConstants } from "../_constants";
import { util } from "../_helpers";
import {
  ISurvey,
  ISurveyMeta,
  ITrigger,
  IUserMerchant,
  IUserPublisher,
} from "../_interfaces";
import { Row } from "../_models";
import { BaseEntity } from "./Base";
import { Question } from "./Question";

export class Survey extends BaseEntity implements ISurvey {
  id: number;
  uuid: string;
  replyVisibility: string;
  status: string;
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  authorId: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  metas: ISurveyMeta[];
  merchant: IUserMerchant;
  publisher?: IUserPublisher | undefined;
  triggers?: ITrigger[] | undefined;
  questions: Question[];

  constructor() {
    super();
    this.id = 0;
    this.uuid = "";
    this.replyVisibility = "";
    this.status = "";
    this.active = true;
    this.title = "";
    this.description = "";
    this.startDate = "";
    this.endDate = "";
    this.createdAt = "";
    this.updatedAt = "";
    this.metas = [];
    this.triggers = [];
    this.questions = [];
  }

  public static fromJson(json: ISurvey): Survey {
    return new Survey().populate(json);
  }

  public static fromJsonArray(json: ISurvey[]): Survey[] {
    return json.map((s) => new Survey().populate(s));
  }

  public populate(json: ISurvey) {
    if (json) {
      this.id = json.id || 0;
      this.uuid = json.uuid || "";
      this.replyVisibility = json.replyVisibility || "";
      this.status = json.status || "";
      this.active = json.active ?? true;
      this.title = json.title || "";
      this.description = json.description || "";
      this.startDate = json.startDate || "";
      this.endDate = json.endDate || "";
      this.createdAt = json.createdAt || "";
      this.updatedAt = json.updatedAt || "";
      this.metas = this.getMetas(json.metas);
      this.merchant = json.merchant;
      this.publisher = json.publisher;
      this.triggers = json.triggers;
      this.authorId = json.authorId || "";
      this.questions = json.questions
        ? json.questions.map((m) => Question.fromJson(m))
        : [];
    }
    return this;
  }

  public get isAwaiting(): boolean {
    return this.status === "AWAITING";
  }

  public get isDraft(): boolean {
    return this.status === "DRAFT";
  }

  public get isExpired(): boolean {
    return this.status === "EXPIRED";
  }

  public get isPublished(): boolean {
    return this.status === "PUBLISHED";
  }

  public get isClosed(): boolean {
    return this.replyVisibility === "PRIVATE";
  }
  public get isOpen(): boolean {
    return this.replyVisibility === "PUBLIC";
  }

  public get infos(): any[] {
    return [
      {
        label: "Titre du questionnaire *",
        value: this.title,
        layout: "col-12",
      },
      {
        label: "Date de début",
        value: util.formatDate("dd/MM/yyyy", this.startDate),
        layout: "col-md-12",
      },
      {
        label: "Heure de début",
        value: this.getMetaValue("startHour") || "-",
        layout: "col-md-12",
      },
      {
        label: "Date de fin",
        value: util.formatDate("dd/MM/yyyy", this.endDate),
        layout: "col-md-12",
      },

      {
        label: "Heure de fin",
        value: this.getMetaValue("endHour") || "-",
        layout: "col-md-12",
      },
      {
        label: "Description",
        value: this.description,
        layout: "col-12",
      },
    ];
  }

  public get designQuestion(): any[] {
    return [
      {
        label: "Couleur du texte",
        value: this.getMetaColor("textColor"),
        layout: "col-md-4",
      },
      {
        label: "Couleur des boutons",
        value: this.getMetaColor("buttonColor"),
        layout: "col-md-4",
      },
      {
        label: "Couleur de fond du questionnaire ",
        value: this.getMetaColor("bgSurvey"),
        layout: "col-md-4",
      },
      {
        label: "Couleur des liens",
        value: this.getMetaColor("linkColor"),
        layout: "col-md-4",
      },
      {
        label: "Couleur de fond la page",
        value: this.getMetaColor("bgColor"),
        layout: "col-md-4",
      },
      {
        label: "Couleur du baromètre",
        value: this.getMetaColor("barometreColor"),
        layout: "col-md-4",
      },
      {
        label: "Taille du baromètre",
        value: appConstants.barometreSize.find((bs) => bs.value == this.getMetaValue("barometreSize"))?.label || "-",
        layout: "col-md-4",
      },
      {
        label: "Texte de fin ou de remerciement",
        value: this.getMetaValue("thankYouText") || "-",
        layout: "col-12",
      },
    ];
  }

  public get designHeader(): any[] {
    return [
      {
        label: "Couleur de fond de l'entête",
        value: this.getMetaColor("bgHeader"),
        layout: "col-md-6",
      },
      {
        label: "Couleur du texte de l'entête",
        value: this.getMetaColor("textColorHeader"),
        layout: "col-md-6",
      },
      {
        label: "Texte de l'entête",
        value: this.getMetaValue("header") || "-",
        layout: "col-md-12",
      }
    ];
  }


  public get designFooter(): any[] {
    return [
      {
        label: "Couleur de fond du pied de page ",
        value: this.getMetaColor("bgFooter"),
        layout: "col-md-6",
      },
      {
        label: "Couleur du texte du pied de page",
        value: this.getMetaColor("textColorFooter"),
        layout: "col-md-6",
      },
      {
        label: "Texte du pied de page",
        value: this.getMetaValue("footer") || "-",
        layout: "col-md-12",
      }
    ];
  }



  getMetaColor(key: string) {
    const a = this.getMetaValue(key);
    if (a) {
      return `<input type="color" disabled class="form-control" value="${a}">`;
    }
    return "-";
  }
  getMetaUrl(key: string) {
    const a = this.getMetaValue(key);
    if (a) {
      return a;
      // return `<a href="${a}" target="_blank">Ouvrir</a>`;
    }
    return "-";
  }
  public get cgus(): any[] {
    return [
      {
        label: "Lien de votre portail fidélité. Ex: avomark.macarte.club",
        helpText: "Lien pour accéder à votre portail fidélité",
        value: this.getMetaUrl("fidelityRegistrationUrl"),
        layout: "col-md-3",
      },
      {
        label: "Texte du lien de votre portail fidélité. Ex: Portail fidélité avomark",
        helpText: "Texte du lien pour accéder à votre portail fidélité",
        value: this.getMetaUrl("fidelityRegistrationText"),
        layout: "col-md-3",
      },
      {
        label: "Lien de désinscription",
        helpText: "Lien pour se désabonner",
        value: this.getMetaUrl("unsubscribeUrl"),
        layout: "col-md-3",
      },
      {
        label: "Lien CGU",
        required: true,
        helpText: "Conditions générales d'utilisation",
        value: this.getMetaUrl("cguUrl"),
        layout: "col-md-3",
      },
      {
        label: "Lien RGPD",
        required: true,
        helpText: "Règlement général sur la protection des données",
        value: this.getMetaUrl("rgpdUrl"),
        layout: "col-md-3",
      },
      {
        label: "Texte du lien de desinscription",
        helpText: "Libellé du texte pointant sur le lien de désinscription",
        value: this.getMetaValue("unsubscribeText") || "-",
        layout: "col-12",
      },
      {
        label: "Texte d'acception des CGU",
        helpText:
          "Le texte que les consommateurs veront avant de valider votre CGU",
        value: this.getMetaValue("cguText") || "-",
        layout: "col-12",
      },
    ];
  }

  getMetas(metas: ISurveyMeta[]): ISurveyMeta[] {
    if (metas?.length) {
      return metas.map((m) => {
        return {
          ...m,
          code: m.code
            .split("_")
            .map((c) => c.toLowerCase())
            .map((c, i) => {
              if (i !== 0) {
                return c.charAt(0).toUpperCase() + c.slice(1);
              }
              return c;
            })
            .join(""),
        };
      });
    }
    return [];
  }

  getMetaValue(key: string) {
    const i = this.metas.findIndex((m) => m.code === key);
    if (i > -1) return this.metas[i].value;
    return "";
  }

  toJson(): ISurvey {
    return {
      id: this.id,
      uuid: this.uuid,
      replyVisibility: this.replyVisibility,
      status: this.status,
      active: this.active,
      title: this.title,
      description: this.description,
      startDate: this.startDate,
      endDate: this.endDate,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      metas: this.metas,
      merchant: this.merchant,
      publisher: this.publisher,
      authorId: this.authorId,
      triggers: this.triggers,
      questions: this.questions.map((m) => m.toJson()),
    };
  }

  toRow() {
    return new Row({
      data: this,
    });
  }

  async loadByUuid(uuid: string) { }
}
