export const appConstants = {
  JWT_TOKEN_KEY: "jwttoken",
  USER_PHONE_KEY: "userphone",
  CURRENT_USER: "current_user",
  API_URL: "https://localhost:7079",
  API_VERSION: "/v1",
  ENV: "dev",
  IS_JSON: true,
  defaultTitle: "Accueil - Tableau de bord",
  barometreSize: [
    {
      label: "petit",
      value: "200%",
    },
    {
      label: "moyen",
      value: "300%",
    },
    {
      label: "grand",
      value: "400%",
    }
  ],
  responseTypes: [
    {
      label: "Réponse courte",
      value: "OPEN_QUESTION",
      previewLink: "/Public/img/survey_response_type/reponse_courte.jpeg",
    },
    {
      label: "Réponse libre",
      value: "OPEN_QUESTION_BIG",
      previewLink: "/Public/img/survey_response_type/reponse_longue.jpeg",
    },
    {
      label: "Liste déroulante simple",
      value: "LISTE_UNIQUE",
      previewLink: "/Public/img/survey_response_type/liste_deroulante_unique.jpeg",
    },
    {
      label: "Liste déroulante multiple",
      value: "LISTE_MULTIPLE",
      previewLink: "/Public/img/survey_response_type/liste_deroulante_multiple.jpeg",
    },
    {
      label: "Case à cocher (Unique)",
      value: "CASE_UNIQUE",
      previewLink: "/Public/img/survey_response_type/case_a_cocher.jpeg",
    },
    {
      label: "Case à cocher (Multiple)",
      value: "CASE_MULTIPLE",
      previewLink: "/Public/img/survey_response_type/case_cocher_multiple.jpeg",
    },
    {
      label: "Baromètre",
      value: "BAROMETRE",
      previewLink:
        "https://avomark-survey.s3.fr-par.scw.cloud/images/type-response-previews/6.webp",
    },
  ],
  validatorTypes: [
    {
      value: "TEXT",
      label: "Chaîne de caractères",
      needMinMax: true
    },
    {
      value: "NUMBER",
      label: "Nombre entier",
      needMinMax: false
    },
    // { value: "DECIMAL", label: "Nombre avec des virgules" },
    {
      value: "DATE",
      label: "Date",
      needMinMax: false
    },
    // { value: "TIME", label: "Heure et minutes" },
    // {
    //   value: "RANGE_NUMBER",
    //   label: "Nombre compris entre un minimun et un maximum",
    //   inputType: "number",
    // },
    // {
    //   value: "RANGE_DOUBLE",
    //   label: "Nombre avec virgules compris entre un minimun et un maximum",
    //   inputType: "number",
    //   step: 0.01,
    // },
    // {
    //   value: "RANGE_DATE",
    //   label: " Date comprise entre un minimun et un maximum",
    //   inputType: "date",
    // },
    // {
    //   value: "RANGE_TIME",
    //   label: "Heure comprise entre un minimun et un maximum",
    //   inputType: "time",
    // },
  ],
  surveyLinks: [
    {
      id: 1,
      indexes: [0, 1],
      label: "Informations",
    },
    {
      id: 2,
      indexes: [2],
      label: "Questions & réponses",
    },
    {
      id: 3,
      indexes: [3],
      label: "CGU & RGPD",
    },
    {
      id: 4,
      indexes: [4],
      label: "Design & Graphisme",
    },
    {
      id: 5,
      indexes: [5],
      label: "Confirmation",
    },
  ],

  surveyStatuses: [
    {
      id: "PUBLISHED",
      label: "Publié",
    },
    {
      id: "DRAFT",
      label: "Brouillon",
    },
    {
      id: "AWAITING",
      label: "En attente de publication",
    },
    {
      id: "EXPIRED",
      label: "Expiré",
    },
  ],
  DEFAULT_SURVEY_COLORS: {
    bgHeader: "#6F529B",
    textColorHeader: "#FFFFFF",
    bgColor: "#FFFFFF",
    bgSurvey: "#FFFFFF",
    textColor: "#000000",
    linkColor: "#43A0F7",
    barometreColor: "#FFDD00",
    bgFooter: "#6F529B",
    textColorFooter: "#FFFFFF",
    buttonColor: "#427fed"
  },
  configsAll: {},
  configsLocal: {
    f2Services: "https://localhost:2082/Index/Services",
    urlShortCode: "https://staging.avk.sh/v1/url/generateShortcode",
    surveyReplyTarget: "https://localhost:7079/Survey/Qrcode?uuid=",
    surveyReplyTestingsurvey: "https://localhost:7079/Survey/TestSurvey?uuid=",
    surveyPreviewTarget: "https://localhost:7079/Survey/Preview?uuid=",
    surveyReplyTest: "https://localhost:7079/Survey/Reply?uuid=",
    surveyUploadLogo:
      "https://staging.api.survey360.avomark.fr/v1/surveys/logo/",
    surveyGetLogo:
      "https://staging.api.survey360.avomark.fr/v1/surveys/get/logo/",
    contentServerUrl:
      "https://staging.web.content.avomark.io/survey/",
    surveyApiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5Mzk5IiwiYXV0aG9ySWQiOiI5Mzk5IiwibXlTaG9wSWQiOiI5Mzk5IiwidXNlcm5hbWUiOiJhYnlzdGVyIiwidXNlclJvbGUiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiMSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiYWJ5c3RlciIsIm5iZiI6MTY5OTU0Mzk5OSwiZXhwIjoxNjk5NjI3MDk5LCJpc3MiOiJGMzYwSXNzdWVyIiwiYXVkIjoiRjM2MEF1ZGllbmNlIn0.kwUcxeZfWdMQWdW3E43IdQbevFR7ds4uPUQxzDAMqys",
    editSurveyLink:
      "https://localhost:7079/api",
    qrcodeLink: "https://staging.avk.sh/",
    f2Login: "https://localhost:2082/Index/Login",
    shortCodeKey: "SHORT_CODE_DEV",
    qrCodeKey: "QR_CODE_DEV",
    repliesLink:
      "https://staging.api.survey360.avomark.fr/v1/survey_replies/by_survey_2/",
  },
  configsStaging: {
    f2Services: "https://staging.fidonline360.avomark.fr/Index/Services",
    f2Login: "https://staging.fidonline360.avomark.fr/Index/Login",
    urlShortCode: "https://staging.avk.sh/v1/url/generateShortcode",
    surveyReplyTarget:
      "https://staging.ui.survey360.avomark.fr/Survey/Qrcode?uuid=",
    surveyReplyTestingsurvey: 
      "https://staging.ui.survey360.avomark.fr/Survey/TestSurvey?uuid=",
    surveyPreviewTarget:
      "https://staging.ui.survey360.avomark.fr/Survey/Preview?uuid=",
    surveyReplyTest:
      "https://staging.ui.survey360.avomark.fr/Survey/Reply?uuid=",
    surveyUploadLogo:
      "https://staging.api.survey360.avomark.fr/v1/surveys/logo/",
    surveyGetLogo:
      "https://staging.api.survey360.avomark.fr/v1/surveys/get/logo/",
    contentServerUrl:
      "https://staging.web.content.avomark.io/survey/",
    surveyApiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5Mzk5IiwiYXV0aG9ySWQiOiI5Mzk5IiwibXlTaG9wSWQiOiI5Mzk5IiwidXNlcm5hbWUiOiJhYnlzdGVyIiwidXNlclJvbGUiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiMSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiYWJ5c3RlciIsIm5iZiI6MTY5OTU0Mzk5OSwiZXhwIjoxNjk5NjI3MDk5LCJpc3MiOiJGMzYwSXNzdWVyIiwiYXVkIjoiRjM2MEF1ZGllbmNlIn0.kwUcxeZfWdMQWdW3E43IdQbevFR7ds4uPUQxzDAMqys",
    editSurveyLink:
      "https://staging.ui.survey360.avomark.fr/api",
    qrcodeLink: "https://staging.avk.sh/",
    shortCodeKey: "SHORT_CODE_STAGING",
    qrCodeKey: "QR_CODE_STAGING",
    repliesLink:
      "https://staging.api.survey360.avomark.fr/v1/survey_replies/by_survey_2/",
  },
  configsProd: {
    f2Services: "https://fidonline360.avomark.fr/Index/Services",
    f2Login: "https://fidonline360.avomark.fr/Index/Login",
    urlShortCode: "https://avk.sh/v1/url/generateShortcode",
    surveyReplyTarget: "https://survey360.avomark.fr/Survey/Qrcode?uuid=",
    surveyPreviewTarget: "https://survey360.avomark.fr/Survey/Preview?uuid=",
    surveyReplyTestingsurvey: 
      "https://survey360.avomark.fr/Survey/TestSurvey?uuid=",
    surveyReplyTest: "https://survey360.avomark.fr/Survey/Reply?uuid=",
    surveyUploadLogo:
      "https://prod.api.survey360.avomark.fr/v1/surveys/logo/",
    surveyGetLogo:
      "https://prod.api.survey360.avomark.fr/v1/surveys/get/logo/",
    contentServerUrl:
      "https://web.content.avomark.io/survey/",
    surveyApiToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI5Mzk5IiwiYXV0aG9ySWQiOiI5Mzk5IiwibXlTaG9wSWQiOiI5Mzk5IiwidXNlcm5hbWUiOiJhYnlzdGVyIiwidXNlclJvbGUiOiIxIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiMSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiYWJ5c3RlciIsIm5iZiI6MTY5OTU0Mzk5OSwiZXhwIjoxNjk5NjI3MDk5LCJpc3MiOiJGMzYwSXNzdWVyIiwiYXVkIjoiRjM2MEF1ZGllbmNlIn0.kwUcxeZfWdMQWdW3E43IdQbevFR7ds4uPUQxzDAMqys",
    editSurveyLink:
      "https://prod.api.survey360.avomark.fr/api",
    qrcodeLink: "https://avk.sh/",
    shortCodeKey: "SHORT_CODE_PROD",
    qrCodeKey: "QR_CODE_PROD",
    repliesLink:
      "https://prod.api.survey360.avomark.fr/v1/survey_replies/by_survey_2/",
  },
};
