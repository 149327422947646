import { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import {
  AdminDashboard,
  AdminQuestionnaires,
  AdminQuestionnairesEdit,
  AdminQuestionnairesNew,
  AdminQuestionnairesShow,
  FullLoader,
  ModalWrapper,
  Navbar,
} from "../_components";
import { useSelector } from "react-redux";
import { RootState } from "../_reducers";

const HomePage = lazy(() => import("../pages/Home"));
const AdminPage = lazy(() => import("../pages/Admin"));
const NotFoundPage = lazy(() => import("../pages/404"));

function App() {
  const model = useSelector((state: RootState) => state.app);
  return (
    <div className="App">
      <FullLoader />
      <Navbar />
      <ModalWrapper />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route element={<PrivateRoute model={model} />}>
            <Route path="/backoffice" element={<AdminPage />}>
              <Route index element={<AdminDashboard />} />
              <Route path="questionnaires">
                <Route index element={<AdminQuestionnaires />} />
                <Route path="new" element={<AdminQuestionnairesNew />} />
                <Route path="edit" element={<AdminQuestionnairesEdit />} />
                <Route path="show" element={<AdminQuestionnairesShow />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="*"
            element={<Navigate to="/backoffice/questionnaires" replace />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
