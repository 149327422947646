import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models";
import { QuestionFormModel } from "../../../../_models/business/survey/question";
import { RootState } from "../../../../_reducers";
import { Question3, Question4 } from "../question";
import { DnD } from "./DnD";

export const SurveyStep5 = (props: StepProps) => {
  const { setStep, form } = props;
  const model = useSelector((state: RootState) => state.app);
  const [responses, setResponses] = useState<any[]>([]);
  const [search] = useSearchParams();
  const uuid = search.get("uuid");

  useEffect(() => {
    const fm = model.page.model!.form as SurveyFormModel;
    fm.getResponses(uuid).then((res) => {
      if (Array.isArray(res.data)) {
        // console.log(res.data.filter((d) => d.data.length));
        setResponses(
          res.data.map((m, i) => {
            const q = new QuestionFormModel({ opened: true, ...m });
            return {
              id: i + 1,
              title: "Reponse #" + (i + 1),
              question: q,
              content: <Question4 question={q} />,
            };
          })
        );
      }
    });
  }, []);
  return (
    <div className="step-pane active">
      <form>
        <DnD items={responses} />
        <hr />
        <a className="btn info btn-next" onClick={() => setStep(6)}>
          <i className="fa fa-arrow-left"></i> Précédent
        </a>
      </form>
    </div>
  );
};
