import { Table } from "../../../shared";

export const PublishedSurveys = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_sended">
      <h1>Publié(s)</h1>
      <Table {...tab} />
    </div>
  );
};
