export const pageConfigs = [
  {
    path: "/backoffice",
    pageTitle: "Accueil - Tableau de bord",
  },
  {
    path: "/backoffice/questionnaires",
    pageTitle: "Gestion des questionnaires",
  },
  {
    path: "/backoffice/questionnaires/new",
    pageTitle: "Gestion des questionnaires",
  },
  {
    path: "/backoffice/questionnaires/edit",
    pageTitle: "Gestion des questionnaires",
  },
];
