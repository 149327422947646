import { useState } from "react";
import { http, util } from "../../../../_helpers";
import { QuestionFormModel } from "../../../../_models/business/survey/question";
import { ValidatorFormModel } from "../../../../_models/business/survey/validator";
import { InputField } from "../../../shared";
import { QuestionValidator } from "./validator";

export const Question = ({
  question,
  onChange,
}: {
  question: QuestionFormModel;
  onChange: any;
}) => {
  const [localQuestion, setLocalQuestion] =
    useState<QuestionFormModel>(question);

  const onDelete = () => {
    util.showConfirm("Voulez-vous vraiment supprimer cette question?", () => {
      localQuestion.removed = true;
      onChange(localQuestion);
      // setLocalQuestion(localQuestion.clone());
    });
  };
  const update = (key: string, value: any) => {
    localQuestion.updateFieldValue({ key, value });
    onChange(localQuestion);
    setLocalQuestion(localQuestion.clone());
  };
  const updateValidator = (value: ValidatorFormModel) => {
    localQuestion.validator = value;
    onChange(localQuestion);
    setLocalQuestion(localQuestion.clone());
  };
  const {
    label,
    required,
    description,
    responseType,
    responses,
    validator,
    removed,
  } = localQuestion;

  return (
    <div className="row" style={{ display: removed ? "none" : "" }}>
      <InputField field={label} onChange={update} />
      <InputField field={required} onChange={update} />
      <InputField field={description} onChange={update} />
      <InputField field={responseType} onChange={update} />
      {localQuestion.needResponses && (
        <InputField field={responses} onChange={update} />
      )}
      {localQuestion.needValidator && (
        <QuestionValidator validator={validator} onChange={updateValidator} />
      )}
      <div className="form-group col-md-12">
        <div style={{ maxWidth: "200px", margin: "auto" }}>
          <button className="btn btn-danger" onClick={() => onDelete()}>
            Supprimer cette question
          </button>
        </div>
      </div>
    </div>
  );
};

export const Question2 = ({
  question,
  onChange,
}: {
  question: QuestionFormModel;
  onChange: any;
}) => {
  const onDelete = () => {
    util.showConfirm(
      "Attention vous êtes sur le point de supprimer la question #14. Cette action est irreversible",
      async () => {
        util.showLoading();
        const res = await question.removeQuestion();
        // console.log(res);
        if (res && res.data) {
          util.reload();
        } else {
          util.showError(
            "Erreur!",
            "Erreur lors de la suppression de la question"
          );
        }
        util.hideLoading();
      }
    );
  };
  const { label, required, description, responseType, responses, validator } =
    question;
  const items = [
    {
      label: "Intitulé",
      required: true,
      value: label.value,
      layout: "col-md-4",
    },
    {
      label: "Cette question est-elle obligatoire?",
      value: required.value == "true" ? "Oui" : "Non",
      layout: "col-md-4",
    },

    {
      label: "Type de reponse",
      value: responseType.value,
      layout: "col-md-4",
    },
    {
      label: "Type de validation",
      value: validator.type.value,
      layout: "col-md-4",
    },
    {
      label: "Longueur minimale",
      value: validator.min.value || "-",
      layout: "col-md-4",
    },
    {
      label: "Longueur maximale",
      value: validator.max.value || "-",
      layout: "col-md-4",
    },
    {
      label: "Responses",
      value:
        responses.value.length > 0
          ? responses.value.map((m) => m.label).join(", ")
          : "-",
      layout: "col-md-12",
    },
    {
      label: "Description",
      value: description.value,
      layout: "col-md-12",
    },
  ];
  return (
    <>
      {items.map((item, i) => {
        return (
          <div key={i} className={"form-group " + item.layout}>
            <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              {item.label}
            </label>
            <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
          </div>
        );
      })}
      {/* <button className="btn btn-sm btn-danger" onClick={() => onDelete()}>
        <i className="fa fa-times mr-2" aria-hidden="true"></i>
        Supprimer cette question
      </button> */}
    </>
  );
};

export const Question3 = ({ question }: { question: QuestionFormModel }) => {
  const items: any[] = [];

  if (Array.isArray(question.data.survey.questions)) {
    question.data.survey.questions.forEach((q) => {
      let txt = "-";
      if (q.answer) {
        if (q.responseType.code == "OPEN_QUESTION") {
          txt = q.answer?.valeur;
        }
        if (q.responseType.code == "BAROMETRE" && q.answer) {
          txt = q.answer?.valeur + " " + "étoile(s)";
        }
        if (
          q.responseType.code == "LISTE_UNIQUE" ||
          q.responseType.code == "CASE_UNIQUE"
        ) {
          const i = (q.responsesOptions || []).findIndex(
            (o) => String(o.id) === String(q.answer?.valeur)
          );
          if (i > -1) {
            txt = (q.responsesOptions || [])[i].label;
          }
        }
        if (
          q.responseType.code == "LISTE_MULTIPLE" ||
          q.responseType.code == "CASE_MULTIPLE"
        ) {
          // console.log(q);
          const parts = q.answer?.valeur.split(",") || [];
          const ress: any[] = [];
          parts.forEach((a) => {
            const i = (q.responsesOptions || []).findIndex(
              (o) => String(o.id) === String(a)
            );
            if (i > -1) {
              ress.push((q.responsesOptions || [])[i].label);
            }
          });
          txt = ress.join(", ");
        }
      }

      items.push({
        label: q.label,
        value: txt,
        layout: "col-md-6",
      });
    });
  }

  items.push(
    ...[
      {
        label: "A accepté les CGUs ?",
        value: question.data.cgu ? "Oui" : "Non",
        layout: "col-md-4",
      },
      {
        label: "A accepté les RGPDs ?",
        value: question.data.rgpd ? "Oui" : "Non",
        layout: "col-md-4",
      },
      {
        label: "Cette reponse est-elle terminé ?",
        value: question.data.finished ? "Oui" : "Non",
        layout: "col-md-4",
      },
    ]
  );

  return (
    <>
      {items.map((item, i) => {
        return (
          <div key={i} className={"form-group " + item.layout}>
            <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              {item.label}
            </label>
            <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
          </div>
        );
      })}
    </>
  );
};

export const Question4 = ({ question }: { question: QuestionFormModel }) => {
  const items: any[] = [];
  const qd = question.data;
  if (Array.isArray(qd.answers)) {
    qd.answers.forEach((q) =>
      items.push({
        label: q.questionName,
        value: q.value,
        layout: "col-md-6",
      })
    );
  }

  items.push(
    ...[
      {
        label: "A accepté les CGUs ?",
        value: qd.cgu ? "Oui" : "Non",
        layout: "col-md-4",
      },
      {
        label: "A accepté les RGPDs ?",
        value: qd.rgpd ? "Oui" : "Non",
        layout: "col-md-4",
      },
      {
        label: "Cette reponse est-elle terminé ?",
        value: qd.finished ? "Oui" : "Non",
        layout: "col-md-4",
      },
    ]
  );

  return (
    <>
      {items.map((item, i) => {
        return (
          <div key={i} className={"form-group " + item.layout}>
            <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              {item.label}
            </label>
            <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
          </div>
        );
      })}
    </>
  );
};
