import { immerable } from "immer";
import { cloneDeep } from "lodash";
import { INITIALISE_SURVEY, appConstants } from "../../../_constants";
import { Survey } from "../../../_entities";
import { db, util } from "../../../_helpers";
import { IFieldUpdatePayload } from "../../../_interfaces";
import { Field } from "../../field";
import { FormModel } from "../../form";
import { QuestionFormModel } from "./question";
import { SurveyTableModel } from "./table";

export class SurveyFormModel extends FormModel {
  [immerable] = true;

  private static _instance: SurveyFormModel | undefined;

  private static _loadingResponses = false;

  //Step1
  title: Field;
  replyVisibility: Field;
  startDate: Field;
  endDate: Field;
  startHour: Field;
  endHour: Field;
  description: Field;

  //Step2
  fidelityRegistrationText: Field;
  fidelityRegistrationUrl: Field;
  unsubscribeUrl: Field;
  cguUrl: Field;
  rgpdUrl: Field;
  unsubscribeText: Field;
  cguText: Field;

  //Step4
  logo: Field;
  bgColor: Field;
  textColor: Field;
  buttonColor: Field;
  bgSurvey: Field;
  linkColor: Field;
  barometreColor: Field;
  barometreSize: Field;
  thankYouText: Field;

  //Step5
  bgHeader: Field;
  header: Field;
  textColorHeader: Field;

  //Step6
  bgFooter: Field;
  footer: Field;
  textColorFooter: Field;


  questions: QuestionFormModel[] = [];
  survey: Survey;

  constructor() {
    if (SurveyFormModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use SurveyFormModel.getInstance() instead of new."
      );
    }
    super();
    this.initFields();
    this.survey = new Survey();
    SurveyFormModel._instance = this;
  }

  public static getInstance(): SurveyFormModel {
    if (!SurveyFormModel._instance) {
      return new SurveyFormModel();
    }
    return SurveyFormModel._instance;
  }

  public get fields(): Field[] {
    return [
      this.title,
      this.replyVisibility,
      this.startDate,
      this.endDate,
      this.startHour,
      this.endHour,
      this.description,
      this.fidelityRegistrationText,
      this.fidelityRegistrationUrl,
      this.unsubscribeUrl,
      this.cguUrl,
      this.rgpdUrl,
      this.unsubscribeText,
      this.cguText,
      this.logo,
      this.bgColor,
      this.textColor,
      this.bgSurvey,
      this.linkColor,
      this.buttonColor,
      this.bgHeader,
      this.textColorHeader,
      this.textColorFooter,
      this.bgFooter,
      this.header,
      this.footer,
      this.barometreColor,
      this.barometreSize,
      this.thankYouText,
    ];
  }

  public get metaFields(): Field[] {
    return [
      this.startHour,
      this.endHour,
      this.fidelityRegistrationText,
      this.fidelityRegistrationUrl,
      this.unsubscribeUrl,
      this.cguUrl,
      this.rgpdUrl,
      this.unsubscribeText,
      this.cguText,
      this.logo,
      this.bgColor,
      this.textColor,
      this.buttonColor,
      this.bgHeader,
      this.bgFooter,
      this.textColorHeader,
      this.textColorFooter,
      this.header,
      this.footer,
      this.bgSurvey,
      this.linkColor,
      this.barometreColor,
      this.barometreSize,
      this.thankYouText,
    ];
  }

  public reset(): SurveyFormModel {
    SurveyFormModel._instance = undefined;
    return SurveyFormModel.getInstance();
  }

  public clone() {
    return cloneDeep(this);
  }

  initFields() {

    const currentDate = new Date().toISOString().split('T')[0];

    this.title = new Field({
      name: "title",
      label: "Titre du questionnaire",
      required: true,
      placeHolder: "Quel est le titre de votre questionnaire ?",
      helpText:
        "Nom que vous donné à votre questionnaire (visible du consommateur)",
      value: this.title ? this.title : ""
    });
    this.replyVisibility = new Field({
      layout: "col-md-4",
      name: "replyVisibility",
      label: "Visibilité et droit d'accès",
      helpText: "Visibilité et droit d'accès",
      type: "select",
      value: "PRIVATE",
      options: [
        { label: "Lien unique par utilisateur", value: "PRIVATE" },
        { label: "Lien public et commun à tous", value: "PUBLIC" },
      ],
    });
    this.startDate = new Field({
      layout: "col-md-6",
      name: "startDate",
      type: "date",
      minValue: currentDate,
      label: "Date de début",
      helpText: "Date de début de validité du questionnaire",
      value: this.startDate ? this.startDate : ""
    });
    this.endDate = new Field({
      layout: "col-md-6",
      name: "endDate",
      type: "date",
      minValue: currentDate,
      label: "Date de fin",
      helpText: "Date de fin de validité du questionnaire",
      value: this.endDate ? this.endDate : ""
    });
    this.startHour = new Field({
      layout: "col-md-6",
      name: "startHour",
      type: "time",
      label: "Heure de début",
      helpText: "Heure de début de validité du questionnaire",
      value: this.startHour ? this.startHour : ""
    });
    this.endHour = new Field({
      layout: "col-md-6",
      name: "endHour",
      type: "time",
      label: "Heure de fin",
      helpText: "Heure de fin de validité du questionnaire",
      value: this.endHour ? this.endHour : ""
    });
    this.description = new Field({
      name: "description",
      type: "richtext",
      label: "Description",
      helpText: "Description de votre questionnaire (visible du consommateur)",
      value: this.description ? this.description : ""
    });
    this.fidelityRegistrationUrl = new Field({
      name: "fidelityRegistrationUrl",
      label: "Lien de redirection sur le bouton de fin du questionnaire",
      placeHolder: "Entrez le lien vers votre portail fidélité",
      helpText: "Lien pour accéder à votre portail fidélité"
    });
    this.fidelityRegistrationText = new Field({
      name: "fidelityRegistrationText",
      label: "Texte à afficher sur le bouton de fin du questionnaire",
      placeHolder: "Entrez le texte du bouton de fin du questionnaire",
      helpText: "Texte à afficher sur le bouton de fin du questionnaire"
    });
    this.unsubscribeUrl = new Field({
      name: "unsubscribeUrl",
      label: "Lien de désinscription",
      helpText: "Lien pour se désabonner"
    });
    this.cguUrl = new Field({
      name: "cguUrl",
      label: "Lien CGU",
      placeHolder: "Entrez le lien vers vos CGU",
      required: true,
      helpText: "Conditions générales d'utilisation",
      value: this.cguUrl ? this.cguUrl : ""
    });
    this.rgpdUrl = new Field({
      name: "rgpdUrl",
      required: true,
      label: "Lien RGPD",
      placeHolder: "Entrez le lien vers vos RGPD",
      helpText: "Règlement général sur la protection des données",
      value: this.rgpdUrl ? this.rgpdUrl : ""
    });
    this.unsubscribeText = new Field({
      name: "unsubscribeText",
      type: "richtext",
      label: "Texte du lien de desinscription",
      helpText: "Libellé du texte pointant sur le lien de désinscription",
      value: this.unsubscribeText ? this.unsubscribeText : ""
    });
    this.cguText = new Field({
      name: "cguText",
      type: "richtext",
      label: "Accepter les CGU/RGPD",
      helpText: "Accepter les CGU/RGPD",
      value: this.cguText ? this.cguText : ""
    });

    //    
    this.bgColor = new Field({
      name: "bgColor",
      layout: "col-md-4",
      type: "color",
      label: "Couleur de fond de la page",
      helpText: "Couleur de fond",
      value: this.bgColor ? this.bgColor : "#FFFFFF"
    });
    this.bgSurvey = new Field({
      name: "bgSurvey",
      layout: "col-md-4",
      type: "color",
      label: "Couleur de fond du questionnaire",
      helpText: "Couleur de fond du questionnaire",
      value: this.bgSurvey ? this.bgSurvey : "#FFFFFF"
    });
    this.linkColor = new Field({
      name: "linkColor",
      layout: "col-md-4",
      label: "Couleur des liens",
      type: "color",
      helpText: "Couleur des liens",
      value: this.linkColor ? this.linkColor : "#43A0F7"
    });
    this.textColor = new Field({
      name: "textColor",
      layout: "col-md-4",
      label: "Couleur du texte",
      type: "color",
      helpText: "Couleur du texte",
      value: this.textColor ? this.textColor : "#000000"
    });
    this.buttonColor = new Field({
      name: "buttonColor",
      layout: "col-md-4",
      label: "Couleur des boutons",
      type: "color",
      helpText: "Couleur des boutons",
      value: this.buttonColor ? this.buttonColor : "#427fed"
    });
    this.barometreColor = new Field({
      name: "barometreColor",
      layout: "col-md-4",
      label: "Couleur des étoiles",
      type: "color",
      helpText: "Les étoiles du baromètre auront cette couleur une fois selectionné",
      value: this.barometreColor ? this.barometreColor : "#FFDD00"
    });
    this.barometreSize = new Field({
      name: "barometreSize",
      layout: "col-md-4",
      label: "Taille des étoiles",
      type: "select",
      options: appConstants.barometreSize,
      helpText: "Les étoiles du baromètre auront la taille choisie",
      value: this.barometreSize ? this.barometreSize : ""
    });
    this.thankYouText = new Field({
      name: "thankYouText",
      label: "Texte de fin ou de remerciement",
      type: "richtext",
      helpText: "Texte affiché à la fin du Questionnaire",
      value: this.thankYouText ? this.thankYouText : ""
    });
    //
    this.logo = new Field({
      layout: "col-md-4",
      name: "logo",
      type: "file",
      label: "Logo",
      data: { tag: "metas", inputAttributes: { accept: "image/*" } },
      helpText: "Logo à afficher dans l'entête du questionnaire. La taille recommandée est 110 x 90px.",
    });
    this.bgHeader = new Field({
      name: "bgHeader",
      layout: "col-md-4",
      label: "Couleur de fond de l'entête",
      type: "color",
      helpText: "Cette couleur apparaitra sur entête du questionnaire",
      value: this.bgHeader ? this.bgHeader : "#6F529B"
    });
    this.textColorHeader = new Field({
      name: "textColorHeader",
      layout: "col-md-4",
      label: "Couleur des textes de l'entête",
      type: "color",
      helpText: "Les textes en entête du questionnaire auront cette couleur",
      value: this.textColorHeader ? this.textColorHeader : "#FFFFFF"
    });
    this.header = new Field({
      name: "header",
      label: "Texte d'entête",
      type: "richtext",
      helpText: "Texte affiché sur l'entête du questionnaire",
      value: this.header ? this.header : ""
    });
    //
    this.bgFooter = new Field({
      name: "bgFooter",
      layout: "col-md-6",
      label: "Couleur de fond du pied de page ",
      type: "color",
      helpText: "Cette couleur apparaitra sur le pied de page du questionnaire",
      value: this.bgFooter ? this.bgFooter : "#6F529B"
    });
    this.textColorFooter = new Field({
      name: "textColorFooter",
      layout: "col-md-6",
      label: "Couleur des textes du pied de page",
      type: "color",
      helpText: "Les textes en entête du questionnaire auront cette couleur",
      value: this.textColorFooter ? this.textColorFooter : "#FFFFFF"
    });
    this.footer = new Field({
      name: "footer",
      label: "Texte du pied de page",
      type: "richtext",
      helpText: "Texte affiché sur le pied de page du questionnaire",
      value: this.footer ? this.footer : ""
    });

  }

  addQuestion(opened = false, data = {}) {
    for (let index = 0; index < this.questions.length; index++) {
      this.questions[index].opened = false;
    }
    this.questions.push(new QuestionFormModel({ opened, ...data }));
  }

  changePosition(sens:string, oldIndex: number){
    if(sens=== "up" && oldIndex > 0){

      let newIndex = oldIndex -1;
      const oldQuestionOnNewIndex = this.questions[newIndex];
      // console.log("oldQuestionOnNewIndex , ", oldQuestionOnNewIndex)
        this.questions[newIndex] = this.questions[oldIndex];
        this.questions[oldIndex] = oldQuestionOnNewIndex;

      //change position by index
      this.questions = this.questions.map((q, i) => {
        let temp = q;
        temp.question.position = i + 1;
        return temp;
        })
      // console.log("new List question =", this.questions)
    }else if(sens=== "down" && oldIndex < this.questions.length -1){
      let newIndex = oldIndex +1;
      const oldQuestionOnNewIndex = this.questions[newIndex];
      // console.log("oldQuestionOnNewIndex , ", oldQuestionOnNewIndex)
        this.questions[newIndex] = this.questions[oldIndex];
        this.questions[oldIndex] = oldQuestionOnNewIndex;

      //change position by index
      this.questions = this.questions.map((q, i) => {
        let temp = q;
        temp.question.position = i + 1;
        return temp;
        })
      // console.log("new List question =", this.questions)
    }

  }
  updateFieldValue(payload: IFieldUpdatePayload) {
    const { key, value } = payload;

    // if (
    //   key === "fidelityRegistrationUrl" ||
    //   key === "unsubscribeUrl" ||
    //   key === "cguUrl" ||
    //   key === "rgpdUrl"
    // ) {
    //   if (!util.check_url_link(value)) {
    //     this[key].error = "INVALID_FORMAT_URL";
    //   } else this[key].error = "";

    //   if (value === "") this[key].error = "EMPTY_URL";
    // } else this[key].error = "";

    this[key].value = value;
    this[key].inputClasses = this[key].inputClasses.filter(
      (f: string) => f !== "error"
    );
  }

  updateQuestion(index: number, question: QuestionFormModel) {
    this.questions[index] = question;
    this.questions = this.questions.filter((q) => !q.removed);
  }

  populateFields() {
    const DEFAULT_SURVEY_COLORS = appConstants.DEFAULT_SURVEY_COLORS;
    
    if (!this.survey.id) return;
    this.title.value = this.survey.title;
    this.endDate.value = this.survey.endDate;
    this.startDate.value = this.survey.startDate;
    this.description.value = this.survey.description;

    this.metaFields.forEach((f) => {
      f.value = this.survey.getMetaValue(f.name);
      if(f.name in DEFAULT_SURVEY_COLORS) {
        if(f.value === "")
        f.value = DEFAULT_SURVEY_COLORS[f.name]
      }
    });

    if (this.survey.questions.length) {
      while (this.questions.length) this.questions.pop();
      this.survey.questions.forEach((q) => {
        this.addQuestion(false, { question: q });
      });
      this.questions[0].opened = true;
    } else {
      this.addQuestion(true);
    }
  }

  resetFields() {
    this.title.value = "";
    this.endDate.value = "";
    this.startDate.value = "";
    this.description.value = "";
  }

  validateTitle() {
    const model = this.clone();
    if (!model.title.value) {
      model.title.error = "SURVEY_TITLE_REQUIRED";
    } else if (model.title.value.length < 5) {
      model.title.error = "SURVEY_TITLE_SHORT";
    } else if (model.title.value.length > 255) {
      model.title.error = "SURVEY_TITLE_LONG";
    } else {
      model.title.error = "";
    }
    if (model.title.error) model.title.inputClasses.push("error");
    return model;
  }

  validateQuestions() {
    const model = this.clone();
    let status = true;


    // for (let index=0 ; index< model.questions.length ) {
    model.questions.forEach((question, index) => {
      if (!question["label"]["value"]) {
        this.questions[index]["label"]["error"] = "REQUIRED_INTITULE";
        status = false;
      }
      // if (!question["description"]["value"]) {
      //   this.questions[index]["description"]["error"] = "REQUIRED_DESCRIPTION";
      //   status = false;
      // }
    });

    return status;
  }
  getBody(final = false) {
    let body: any = {};
    this.fields.forEach((f) => {
      Object.assign(body, { [f.name]: f.value });
    });
    Object.assign(body, {
      questions: this.questions.map((q, i) => q.getBody(i)),
    });
    /*try {
      if (
        JSON.stringify(db.get("survey_payload")) === JSON.stringify(body) &&
        !final
      ) {
        return {};
      }
    } catch (error) {}
    console.log(body);
    db.set("survey_payload", body);*/
    return body;
  }

  getPath() {
    return this.survey.uuid ? `/surveys/${this.survey.uuid}` : `/surveys`;
  }
  isLock() {
    return db.get("saving_survey");
  }
  setLock() {
    return db.set("saving_survey", true);
  }
  releaseLock() {
    return db.remove("saving_survey");
  }

  doRequest(body: any) {
    return this.survey.uuid ? this.put(body) : this.post(body);
  }

  async save(init = false, quiet = false, final = false) {
    const data = { ...this.getBody(final), final };
    const path = this.getPath();

    // Object.keys(data).forEach((k) => {
    //   if (!data[k]) delete data[k];
    // });
    
    // console.log("DATA", data);
    // console.log("1", Object.keys(data).length, data.title);
    return new Promise(async (resolve) => {
      if (Object.keys(data).length === 0 || !data.title) {
        return resolve(this);
      }
      // console.log("2");
      //this.setLock();
      // console.log("3");
      if (!quiet) util.showLoading(INITIALISE_SURVEY);
      const res = await this.doRequest({ path, data });
      if (res.data && res.data.id) this.survey.populate(res.data as any);
      this.populateFields();
      if (!init && !quiet) util.hideLoading();
      //this.releaseLock();
      // console.log("4");
      resolve(this);
    });
  }

  async ensureHasShortCodeAndURL(res) {
    if (res.data && res.data.id) {
      if (util.isDev()) {
        const i = res.data.metas.findIndex((m) => m.code === "SHORT_CODE_DEV");
        if (i < 0) {
        }
      }
    }
    return res;
  }
  
  async init(uuid: string | null) {
    return new Promise<SurveyFormModel>(async (resolve) => {
      if (uuid) {
        if (!sessionStorage.getItem("survey_lock")) {
          // console.log("====== OK ====");
          try {
            sessionStorage.setItem("survey_lock", "1");
            util.showLoading(INITIALISE_SURVEY);
            let res: any = await this.get(`/surveys/${uuid}`);
            res = await SurveyTableModel.getInstance().onQrcode(
              { data: { data: res.data } },
              false,
              res
            );

            // console.log("===== data nonorder =======>  ", res.data);
            //reorder question before
            let datas =  {
              ...res.data,
              questions: res.data.questions.sort((a,b)=> a.position - b.position)
            }

            // console.log("===== data order =======>  ", datas);
            if (res.data && res.data.id) {
              this.survey.populate(datas);
            }
          } catch (error) {
            console.error(error);
          }
          util.hideLoading();
          sessionStorage.removeItem("survey_lock");
        }
      } else {
        this.survey = new Survey();
        this.resetFields();
      }
      this.populateFields();
      resolve(this);
    });
  }

  async getResponses(uuid: string | null) {
    if (SurveyFormModel._loadingResponses) {
      return [] as any[];
    }
    SurveyFormModel._loadingResponses = true;
    return new Promise<any>(async (resolve) => {
      util.showLoading(INITIALISE_SURVEY);
      const res = await this.get(`/survey_replies/by_survey_2/${uuid}`);

      util.hideLoading();
      SurveyFormModel._loadingResponses = false;
      resolve(res);
    });
  }
}
