import { useState } from "react";
import { util } from "../../../_helpers";
import { Field } from "../../../_models";

export const InputTag = ({
  field,
  onChange,
}: {
  field: Field;
  onChange: any;
}) => {
  const [newTag, setNewTag] = useState<string>("");
  const onNewTag = () => {
    if (newTag.length) {
      const values = field.value || [];
      values.push({
        id: 0,
        label: newTag,
        active: true,
      });
      onChange(field.name, values);
      setNewTag("");
    }
  };

  const onRemoveTag = (index: number) => {
    const values = field.value || [];
    values.splice(index, 1);
    onChange(field.name, values);
  };

  return (
    <div {...{ className: "form-group " + field.layout }}>
      <label htmlFor={field.name}>
        {field.label}
        {field.required && (
          <i className="required-label" data-placement="right">
            *
          </i>
        )}
        <i
          className="fa fa-question-circle"
          style={{ cursor: "pointer" }}
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title={field.helpText}
        ></i>
      </label>
      <div
        style={{
          height: "135px",
          overflow: "auto",
          border: "1px solid #ccc",
          borderRadius: "0.35rem",
        }}
      >
        {field.value &&
          field.value.map((v: any, i: number) => {
            return (
              <span
                key={i}
                style={{
                  display: "inline-block",
                  padding: "5px",
                  background: "#ddd",
                  margin: "5px",
                  borderRadius: "0.7rem",
                }}
              >
                {v.label}{" "}
                <i
                  className="fa fa-times p-1"
                  onClick={() => onRemoveTag(i)}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            );
          })}
      </div>
      <div className="row pt-3">
        <div className="col-md-10">
          <input
            type="text"
            className={field.classNames}
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onNewTag();
            }}
          />
          {field.error && (
            <span style={{ color: "red" }}>
              {util.getErrorByKey(field.error)}
            </span>
          )}
        </div>
        <div className="col-md-2">
          <a className="btn btn-success btn-next" onClick={() => onNewTag()}>
            <i className="fa fa-plus"></i> Ajouter
          </a>
        </div>
      </div>
    </div>
  );
};
