import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from "../../../../_interfaces";
import { RootModel, SurveyFormModel } from "../../../../_models";
import { replaceForm, RootState } from "../../../../_reducers";
import { Loader, CheckSuccess } from "../../../shared";
import { util } from "../../../../_helpers";
import { useSearchParams } from "react-router-dom";
import { SurveyTableModel } from "../../../../_models/business/survey/table";


declare var $: any;

export const SurveyStep5 = ({ step, setStep }: StepProps) => {
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);
  const [data, setData] = useState<any>({
    loading: true,
    success: false,
  });
  const [setToAwaiting, setAwaiting] = useState<any>({
    awaiting: false
  })
  const m = model.page.model!.form as SurveyFormModel;

  useEffect(() => {
    // const m = model.page.model!.form as SurveyFormModel;
    m!.save(false, true, false).then((res) => {
      setData({
        loading: false,
        success: true,
      });
      dispatch(replaceForm(m));
    });
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }, []);

  const validateSurvey = (e) => {
    setAwaiting({
      awaiting: true
    });
    setData({
      loading: true,
      success: false
    })
    m!.save(false, true, true).then((res) => {
      dispatch(replaceForm(m));
      setData({
        loading: false,
        success: true,
      });
      // util.goTo("/backoffice/questionnaires");
    });
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }

  const setToDraft = async () => {
    setData({
      loading: true,
      success: false,
    });
    setAwaiting({awaiting: false});
    const path = `${util.getConfigs().editSurveyLink}`;
    const path2 = `/v1/surveys/${search.get("uuid")}`;
    const datas = {
      path: path2,
      data: {status: 'DRAFT'}
    }
    await fetch(path, {
      method: 'PUT',
      headers:{
        'Authorization': 'Bearer ' + util.getConfigs().surveyApiToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(datas)
    }).then(res => {
      if(!res.ok){
        throw new Error(`Erreur HTTP! Statut: ${res.status}`);
      }
      setData({
        loading: false,
        success: true,
      });
      setStep(1);
      dispatch(replaceForm(m));
    }).catch(error => {
      console.error('Erreur lors de la mise à jour du statut :', error);
      return;
    });
  }

  const { loading, success } = data;
  const { awaiting } = setToAwaiting;
  const [search] = useSearchParams();
  const previewUrl = util.getConfigs().surveyPreviewTarget + search.get("uuid");
  const backToSurveyLink = () => {
    util.goTo("/backoffice/questionnaires")
  }
  // const editLink = `/backoffice/questionnaires/edit?uuid=${search.get("uuid")}`

  return (
    <div className="step-pane active p-3">
      <div className="p-3">
        {success && <CheckSuccess />}
        {loading && <Loader />}
      </div>
      { awaiting ? loading && <p className="mailing_imgCaption">Soumission en cours...</p> : loading && <p className="mailing_imgCaption">Sauvegarde en cours...</p>}
      {success && (
        <p className="mailing_imgCaption">
          Questionnaire enregistré !{" "}
          <a href={previewUrl} target="_blank">Prévisualiser ce questionnaire</a>
          <br /><br />
          { awaiting ? <a href="#" onClick={setToDraft}>Repasser à brouillon </a> : null}
          &ensp;
          { awaiting ? <a href="#" onClick={backToSurveyLink}>Retour aux questionnaires </a> : <a href="#" onClick={validateSurvey}>Soumettre pour validation </a> }
        </p>
      )}
      <p></p>
    </div>
  );
};
