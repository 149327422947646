import { immerable } from "immer";
import { IFieldUpdatePayload } from "../_interfaces";
import { RootModel } from "./root";

export abstract class FormModel extends RootModel {
  [immerable] = true;

  [key: string]: any;

  updateFieldValue(payload: IFieldUpdatePayload) {}

  clone(): FormModel {
    return this;
  }

  compare(source: any, other: any) {
    return JSON.stringify(source) === JSON.stringify(other);
  }
}
