import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState, updateMenus } from "../../../_reducers";
import { SidebarMenu } from "./SidebarMenu";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);

  useEffect(() => {
    model.ui.initUi().then((menus) => {
      dispatch(updateMenus(menus));
    });
  }, []);

  return <div />;

  if (model.ui.menus.length) {
    return (
      <div
        id="sidebar"
        className={`page-sidebar  ${!model.auth.isSurveyList ? "" : "hide"}`}
      >
        <ul className="nav sidebar-menu">
          {model.ui.menus.map((m, i) => {
            return <SidebarMenu key={i} menu={m} />;
          })}
        </ul>
      </div>
    );
  }

  return (
    <div
      id="sidebar"
      className={`page-sidebar  ${!model.auth.isSurveyList ? "" : "hide"}`}
    >
      <ul className="nav sidebar-menu">
        <li>Aucun menu disponible</li>
      </ul>
    </div>
  );
};
