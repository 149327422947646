import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { util } from "../../../../_helpers";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models/business/survey/form";
import { QuestionFormModel } from "../../../../_models/business/survey/question";
import { replaceForm, RootState } from "../../../../_reducers";
import { Question } from "../question";
import { DnD } from "./DnD";

declare var $: any;

export const SurveyStep2 = (props: StepProps) => {
  const { setStep } = props;
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel>(props.form);

  const onChange = (index: number, question: QuestionFormModel) => {
    console.log(question);
    const m = model.page.model!.form as SurveyFormModel;
    m.updateQuestion(index, question);
    setForm(m.clone());
    dispatch(replaceForm(m));
    if (question.removed) {
      setTimeout(() => {
        setQuestions(getQuestions());
      }, 400);
    }
  };
  useEffect(() => {
    const m = model.page.model!.form as SurveyFormModel;
    setForm(m);
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);


  }, [model.page.model!.form]);

  const getQuestions = (questions?:any) => {
    const m = model.page.model!.form as SurveyFormModel;

    if(questions)
        return questions.map((q, i) => {
            return {
            id: i + 1,
            title: "Question #" + (i + 1),
            question: q,
            content: (
                <Question
                question={q}
                onChange={(qu: QuestionFormModel) => onChange(i, qu)}
                />
            ),
            };
        });


    return m.questions.map((q, i) => {
      return {
        id: i + 1,
        title: "Question #" + (i + 1),
        question: q,
        content: (
          <Question
            question={q}
            onChange={(qu: QuestionFormModel) => onChange(i, qu)}
          />
        ),
      };
    });
  };

  const [questions, setQuestions] = useState<any>(getQuestions());

  const next = () => {
    const m = form!.validateQuestions();

    if (!m) {
      return util.showError(
        "Erreur!",
        "vous devez renseigner l'intitulé et la description de chaque question"
      );
    }
    dispatch(replaceForm(form));
    setStep(3);
  };

  const onAddQuestion = () => {
    const m = form.clone();
    m.addQuestion(true);
    setForm(m);
    dispatch(replaceForm(m));
    const qs = getQuestions();
    setQuestions(qs);
    setTimeout(() => {
      const element = document.getElementById("q" + qs.length);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 400);
  };


  function changePosition(sens, question, ind){
    console.log("questionv ", question?.question?.id , "sens ", sens, "index ", ind)
    const m = form.clone();
    m.changePosition(sens, ind-1);
    setForm(m);
    dispatch(replaceForm(m));


    let qs = getQuestions();

    // let oldIndex = ind-1;
    //
    // if(sens=== "up" && oldIndex > 0){
    //
    //   let newIndex = oldIndex -1;
    //   const oldQuestionOnNewIndex = qs[newIndex];
    //   console.log("oldQuestionOnNewIndex , ", oldQuestionOnNewIndex)
    //   console.log("oldIndex  ", oldIndex, " newIndex ", newIndex)
    //   qs[newIndex] = qs[oldIndex];
    //
    //   console.log(" newIndex value ", qs[oldIndex])
    //   qs[oldIndex] = oldQuestionOnNewIndex;
    //
    //   console.log("new List question =", qs)
    // }


    setQuestions([])
    setTimeout(() => {
      setQuestions( [ ...qs]);
    }, 0.1);
  }

  // useEffect(() => {
  //   let qs = getQuestions();
  //   console.log("form change ========++++++>")
  //
  //   setTimeout(() => {
  //     setQuestions(qs);
  //   }, 100);
  // }, [form]);


  return (
    <div className="step-pane active">
      <DnD items={questions}  onChangePosition={changePosition} />
      <div style={{ paddingTop: "1rem" }}>
        <a
          onClick={() => onAddQuestion()}
          className="btn btn-info mr-2"
          style={{ marginRight: "10px" }}
        >
          + Ajouter une question
        </a>
      </div>
      <hr />
      <a onClick={() => setStep(1)} className="btn info btn-next mr-2">
        <i className="fa fa-arrow-left"></i> Précédent
      </a>
      <a onClick={() => next()} className="btn info btn-next">
        <i className="fa fa-arrow-right"></i> Suivant
      </a>
    </div>
  );
};
