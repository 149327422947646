import { ws } from "./window";

const get = (key: string) => {
  const dataString =
    ws.localStorage.getItem(key) || ws.sessionStorage.getItem(key) || "";
  try {
    return JSON.parse(dataString);
  } catch (error) {}
  return dataString;
};

const remove = (key: string) => {
  ws.localStorage.removeItem(key);
  ws.sessionStorage.removeItem(key);
};

const set = (key: string, data: any, session = false) => {
  const dataString =
    typeof data === "object" ? JSON.stringify(data) : String(data);
  if (session) {
    ws.sessionStorage.setItem(key, dataString);
  } else {
    ws.localStorage.setItem(key, dataString);
  }
};

const clear = () => {
  ws.localStorage.clear();
  ws.sessionStorage.clear();
};

export const db = { get, set, clear, remove };
