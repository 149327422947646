import { immerable } from "immer";
import { cloneDeep } from "lodash";
import { BusinessModel } from "../business";
import { SurveyDisplayModel } from "./display";
import { SurveyFormModel } from "./form";
import { SurveyTableModel } from "./table";

export * from "./form";

export class SurveyModel extends BusinessModel {
  [immerable] = true;

  private static _instance: SurveyModel | undefined;

  table: SurveyTableModel;
  form: SurveyFormModel;
  display: SurveyDisplayModel;

  constructor() {
    if (SurveyModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use SurveyModel.getInstance() instead of new."
      );
    }
    super();
    this.table = SurveyTableModel.getInstance();
    this.form = SurveyFormModel.getInstance();
    this.display = SurveyFormModel.getInstance();
    SurveyModel._instance = this;
  }

  public static getInstance(): SurveyModel {
    if (!SurveyModel._instance) {
      return new SurveyModel();
    }
    return SurveyModel._instance;
  }

  public clone() {
    return cloneDeep(this);
  }

  public reset(): SurveyModel {
    SurveyModel._instance = undefined;
    return SurveyModel.getInstance();
  }

  async handleEvent(data: any) {
    const { key, navigate, href } = data;
    if (key === "a.ondraft") {
      return await this.table.onDraft(data);
    } else if (key === "a.onpublish") {
      return await this.table.onPublish(data);
    } else if (key === "a.onawaiting") {
      return await this.table.onAwaiting(data);
    } else if (key === "a.onexpired") {
      return await this.table.onExpired(data);
    } else if (key === "a.onactivate") {
      return await this.table.onActivate(data);
    } else if (key === "a.ondeactivate") {
      return await this.table.onDeActivate(data);
    } else if (key === "a.onqrcode") {
      return await this.table.onQrcode(data);
    } else if (key === "a.onexport") {
      return await this.table.onExport(data);
    } else if (key === "a.ondelete") {
      return await this.table.onDelete(data);
    }
    else if (key === "a.onshow" || key === "a.onedit") {
      return navigate(href);
    }
  }
}
