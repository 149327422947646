import { appConstants } from "../_constants";
import { db } from "../_helpers/storage";
import { IUser, IUserAuth, IUserAuthPayload } from "../_interfaces";
import { BaseEntity } from "./Base";

export class User extends BaseEntity implements IUser {
  id: number;
  username: string;
  password: string;
  terminal: number;
  role: string;
  acces: string;
  userId: number;
  authorId: number;
  fullName: string;
  lastToken: string;
  lastTokenTimestamp: number;
  createdAt: string;
  updatedAt: string;
  active: boolean;

  public static fromJson(json: IUser): User {
    return new User().populate(json);
  }

  public static fromSession(): User {
    let entity = new User();
    try {
      return entity.populate(db.get(appConstants.CURRENT_USER));
    } catch (error) {
      console.error(error);
    }
    return entity;
  }

  public populate(json: IUser) {
    if (json) {
      this.id = json.id;
      this.username = json.username;
      this.password = json.password;
      this.terminal = json.terminal;
      this.lastToken = json.lastToken || (json as any).token;
      this.role = json.role;
      this.userId = json.userId;
      this.authorId = json.authorId;
      this.fullName = json.fullName;
      this.lastTokenTimestamp = json.lastTokenTimestamp;
      this.createdAt = json.createdAt;
      this.updatedAt = json.updatedAt;
      this.active = json.active;
    }
    return this;
  }

  public static fromAuth(json: IUserAuth): User {
    let entity = new User();
    entity.username = json.username;
    entity.password = json.password;
    entity.terminal = json.terminal;
    entity.acces = json.acces;
    return entity;
  }

  toJson(): IUser {
    return {
      id: this.id,
      username: this.username,
      password: this.password,
      terminal: this.terminal,
      lastToken: this.lastToken,
      role: this.role,
      userId: this.userId,
      authorId: this.authorId,
      fullName: this.fullName,
      lastTokenTimestamp: this.lastTokenTimestamp,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      active: this.active,
    };
  }

  toJsonAuth(): IUserAuth {
    return {
      username: this.username,
      password: this.password,
      terminal: this.terminal,
      acces: this.acces,
    };
  }

  toJsonAuthPayload(): IUserAuthPayload {
    return {
      path: "/users/authenticate",
      data: this.toJsonAuth(),
    };
  }

  saveLocal() {
    db.set(appConstants.JWT_TOKEN_KEY, this.lastToken);
    db.set(appConstants.CURRENT_USER, this.toJson());
  }

  clearLocal() {
    db.clear();
  }

  async validateAuth() {
    if (!this.username) {
      return "Le nom d'utilisateur renseigné est invalide !";
    } else if (!this.password) {
      return "Le mot de passe renseigné est invalide !";
    } else if (!this.terminal) {
      return "Le numéro de terminal renseigné est invalide !";
    }
    return null;
  }
}
