export function CheckSuccess() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "50%",
        margin: "auto",
        height: "160px",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        enableBackground="new 0 0 50 50"
        version="1.1"
        viewBox="0 0 50 50"
        xmlSpace="preserve"
        style={{ maxWidth: "150px" }}
      >
        <circle cx="25" cy="25" r="25" fill="#25AE88"></circle>
        <path
          fill="none"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M38 15L22 33 12 25"
        ></path>
      </svg>
    </div>
  );
}
