import {
  IQuestion,
  IQuestionValidator,
  IResponseItem,
  IResponseType,
} from "../_interfaces";
import { BaseEntity } from "./Base";

export class Question extends BaseEntity implements IQuestion {
  id: string;
  label: string;
  description: string;
  position: number;
  active: boolean;
  required: boolean;
  responses: IResponseItem[];
  responseType: IResponseType;
  validator?: IQuestionValidator | undefined;

  public static fromJson(json: IQuestion): Question {
    return new Question().populate(json);
  }

  public populate(json: IQuestion) {
    if (json) {
      this.id = json.id;
      this.label = json.label;
      this.description = json.description;
      this.position = json.position;
      this.active = json.active;
      this.required = json.required;
      this.responseType = json.responseType;
      this.validator = json.validator;
      this.responses = (json as any).responsesOptions;
    }
    return this;
  }

  toJson(): IQuestion {
    return {
      id: this.id,
      label: this.label,
      description: this.description,
      position: this.position,
      active: this.active,
      required: this.required,
      responseType: this.responseType,
      validator: this.validator,
      responses: this.responses,
    };
  }
}
