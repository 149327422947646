import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { util } from "../../_helpers";
import { RootState } from "../../_reducers";

export const BackofficeTitle = () => {
  const nav = useLocation();
  const model = useSelector((state: RootState) => state.app);
  return (
    <div className="page-header position-relative">
      <div className="header-title">
        <h1>{util.getTitle(nav)}</h1>
      </div>
      <div className="header-buttons">
        {!model.auth.isSurveyList && (
          <a className="sidebar-toggler" href="#">
            <i className="fa fa-arrows-h"></i>
          </a>
        )}
        <a className="refresh" id="refresh-toggler" href="">
          <i className="glyphicon glyphicon-refresh"></i>
        </a>

        <a className="fullscreen" id="fullscreen-toggler" href="#">
          <i className="glyphicon glyphicon-fullscreen"></i>
        </a>
      </div>
    </div>
  );
};
