import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SurveyFormModel } from "../../../_models/business/survey/form";
import { replaceForm, RootState } from "../../../_reducers";
import { appConstants } from "../../../_constants";
import { util } from "../../../_helpers";
import { ShowSurveySteps } from "./show_steps";
import { SurveyTableModel } from "../../../_models/business/survey/table";

export const AdminQuestionnairesShow = (props: any) => {
  const { title, subtitle, edit } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const uuid = search.get("uuid");
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel | undefined>();
  const [step, setStep] = useState<number>(edit ? 1 : 0);

  const onStep = (s: number) => {
    setStep(s);
  };
  const setStepAsync = async (s: number, data?: any) => {
    setStep(s);
  };

  useEffect(() => {
    const f = model.page.model!.form as SurveyFormModel;
    if (uuid && uuid.length && uuid !== f.survey.uuid) {
      util.showLoading();
      setTimeout(() => {
        f.init(uuid).then(async (m) => {
          if (!m.survey.id) {
            /*util.showError(
              "Erreur!",
              "Erreur lors de l'affichage des détails du questionnaire"
            );
            navigate("..");*/
          } else {
            dispatch(replaceForm(m));
            setForm(m);
            setStep(1);
          }
        });
      }, 1000);
    } else {
      const m = model.page.model!.form as SurveyFormModel;
      setForm(m);
    }
  }, [uuid]);

  let links = appConstants.surveyLinks;
  links[0].label = subtitle ?? links[0].label;

  if (form?.survey.isPublished === false && links.length > 5) {
    links.pop();
  } else {
    if (form?.survey.isPublished === true) {
      links = appConstants.surveyLinks;
      links[4].label = "Réponses";
    }
  }

  return (
    <div className="widget">
      <div className="widget-header ">
        <span className="widget-caption">
          {title ??
            "Ajouter un questionnaire. Les champs marqués d'une * sont obligatoires"}
        </span>
        <div className="widget-buttons">
          <a href="#" data-toggle="maximize">
            <i className="fa fa-expand"></i>
          </a>
          <a href="#" data-toggle="collapse">
            <i className="fa fa-minus"></i>
          </a>
        </div>
      </div>
      <div className="widget-body">
        <div className="wizard">
          <ul className="steps">
            {links.map((l, i) => {
              return (
                <li
                  key={i}
                  onClick={() => onStep(l.id)}
                  className={l.indexes.indexOf(step) !== -1 ? "active" : ""}
                >
                  <span className="step">{l.id}</span> {l.label}
                  <span className="chevron"></span>
                </li>
              );
            })}
          </ul>
          <div className="actions">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-default btn-sm shiny btn-prev"
            >
              <i className="fa fa-angle-left"></i>Retour aux questionnaires
            </button>
            {form?.survey.isDraft && (
              <button
                onClick={() =>
                  navigate(
                    `/backoffice/questionnaires/edit?uuid=${form?.survey.uuid}`
                  )
                }
                className="btn btn-default btn-sm shiny btn-next"
              >
                Modifier<i className="fa fa-angle-right"></i>
              </button>
            )}
          </div>
        </div>
        {form && (
          <ShowSurveySteps form={form} step={step} setStep={setStepAsync} />
        )}
      </div>
    </div>
  );
};
