import { IField, IFieldOption } from "../_interfaces";

export class Field {
  layout: string;
  type: string;
  inputClasses: string[];
  name: string;
  label: string;
  placeHolder?: string;
  error: string;
  value: any;
  minValue: any;
  enable: boolean;
  required?: boolean;
  helpText: string;
  options: IFieldOption[];
  data: { [key: string]: any; step?: number; tag?: string };

  constructor(config: IField) {
    this.layout = config.layout || "col-12";
    this.type = config.type || "text";
    this.inputClasses = config.inputClasses
      ? config.inputClasses.concat(["form-control"])
      : ["form-control"];
    this.name = config.name;
    this.helpText = config.helpText;
    this.placeHolder = config.placeHolder || undefined;
    this.minValue = config.minValue || undefined;
    this.label = config.label;
    this.value = config.value || "";
    this.options = config.options || [];
    this.enable = config.enable || true;
    this.required = config.required;
    this.data = config.data || { tag: "root" };
    this.error = config.error || "";
  }

  public get classNames(): string {
    return this.inputClasses.join(" ");
  }
}
