export const eventKeys = {
  TABLE_DATA_CHANGE: "TABLE_DATA_CHANGE",
  TABLE_BTN_CLICK: "TABLE_BTN_CLICK",
};

export const renderKeys = {
  RENDER_ID: "RENDER_ID",
  RENDER_ACTIONS: "RENDER_ACTIONS",
  RENDER_ACTIVE: "RENDER_ACTIVE",
  RENDER_SURVEY_TYPE: "RENDER_SURVEY_TYPE",
};

export const transformKeys = {
  TRANSFORM_SURVEY_STATUS: "TRANSFORM_SURVEY_STATUS",
};
