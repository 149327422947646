import { IQuestionValidator } from "../_interfaces";
import { BaseEntity } from "./Base";

export class QuestionValidator
  extends BaseEntity
  implements IQuestionValidator
{
  id: string;
  label: string;
  type: string;
  min: string;
  max: string;
  active: boolean;

  public static fromJson(json: IQuestionValidator): QuestionValidator {
    return new QuestionValidator().populate(json);
  }

  public populate(json: IQuestionValidator) {
    if (json) {
      this.id = json.id;
      this.label = json.label;
      this.type = json.type;
      this.min = json.min;
      this.max = json.max;
      this.active = json.active;
    }
    return this;
  }

  toJson(): IQuestionValidator {
    return {
      id: this.id,
      label: this.label,
      type: this.type,
      min: this.min,
      max: this.max,
      active: this.active,
    };
  }
}
