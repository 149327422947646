import { Table } from "../../../shared";

export const All = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_all">
      <h1>Tous les questionnaires</h1>
      <Table {...tab} />
    </div>
  );
};
