import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, createSearchParams } from "react-router-dom";
import { util } from "../../../../_helpers";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models/business/survey/form";
import { replaceForm, RootState } from "../../../../_reducers";
import { InputField } from "../../../shared";
import { start } from "repl";

declare var $: any;

export const SurveyStep1 = (props: StepProps) => {
  const { step, setStep } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel>(props.form);

  const next = async () => {
    if (step === 0) {
      const m = form!.validateTitle();
      if (!m.title.error) {
        await m.save(true);
        if (m.survey.uuid) {
          setTimeout(() => {
            setForm(m);
            dispatch(replaceForm(m));
          }, 400);
          return navigate({
            pathname: "../new",
            search: createSearchParams({
              uuid: m.survey.uuid,
            }).toString(),
          });
        }
        return util.showError(
          "Erreur!",
          "Erreur lors de l'enregistrement du questionnaire"
        );
      }
    } else {
      const m = form!.validateTitle();
      if (!m.title.error) {
        m.save(false, true);
        let startDate = window.document.getElementById("startDate") as HTMLInputElement;
        let endDate = document.getElementById("endDate") as HTMLInputElement;

        if(new Date(startDate!.value) > new Date(endDate!.value)) 
        {
          util.showError(
            "Erreur!",
            "La date de fin doit être supérieur à la date de début"
          );
        }
        else
        {
          setStep(2);
        }
        // console.log("START DATE", startDate!.value);
        // console.log("END DATE", endDate!.value);
        
      }
    }
  };

  const onChange = (key: string, value: any) => {
    form!.updateFieldValue({ key, value });
    setForm(form!.clone());
    dispatch(replaceForm(form!));
  };
  useEffect(() => {
    const m = model.page.model!.form as SurveyFormModel;
    setForm(m);
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }, [model.page.model!.form]);

  const { title, startHour, endHour, startDate, endDate, description } = form!;

  // console.log(title);
  return (
    <div className="step-pane active">
      <form>
        {step === 0 && (
          <div className="form-group">
            <h3>Donner un titre à votre questionnaire pour commencer</h3>
            <hr />
          </div>
        )}
        <div className="row">
          <InputField field={title} onChange={onChange} />
        </div>
        {step === 1 && (
          <div className="row description-full-screen">
            {/*<InputField field={replyVisibility} onChange={onChange} />*/}
            <InputField field={startDate} onChange={onChange} />
            <InputField field={endDate} onChange={onChange}/>
            <InputField field={startHour} onChange={onChange} />
            <InputField field={endHour} onChange={onChange} />
            <InputField field={description} onChange={onChange} />
          </div>
        )}
        <hr />
        <a className="btn info btn-next" onClick={() => next()}>
          <i className="fa fa-arrow-right"></i>{" "}
          {step === 0 ? "Commencer" : "Suivant"}
        </a>
      </form>
    </div>
  );
};
