import { IMenu } from "../_interfaces";
import { BaseEntity } from "./Base";

export class Menu extends BaseEntity implements IMenu {
  id: string;
  label: string;
  icon: string;
  path: string;
  active: boolean;
  children: Menu[];

  public static fromJson(json: IMenu): Menu {
    return new Menu().populate(json);
  }

  public populate(json: IMenu) {
    if (json) {
      this.id = json.id;
      this.label = json.label;
      this.icon = json.icon;
      this.path = json.path;
      this.active = json.active;
      this.children = json.children
        .filter((m) => m.active)
        .map((m) => Menu.fromJson(m));
    }
    return this;
  }

  toJson(): IMenu {
    return {
      id: this.id,
      label: this.label,
      icon: this.icon,
      path: this.path,
      active: this.active,
      children: this.children.map((m) => m.toJson()),
    };
  }
}
