export const FullLoader = () => {
  return (
    <div id="loading">
      <div>
        <h2>Traitement en cours...</h2>
        <span></span>
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    </div>
  );
};
