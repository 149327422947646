import { Table } from "../../../shared";

export const Awaiting = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_planned">
      <h1>En attente(s)</h1>
      <Table {...tab} />
    </div>
  );
};
