import axios, { AxiosInstance, AxiosResponse } from "axios";
import { appConstants } from "../_constants";
import { db } from "./storage";
import { util } from "./util";

const { JWT_TOKEN_KEY, IS_JSON } = appConstants;
// console.log(process.env);
export const http = {
  get,
  post,
  put,
  remove,
  patch,
  getInstance,
  postExternal,
};

async function getInstance(multipart?: string): Promise<AxiosInstance> {
  return new Promise<AxiosInstance>(async (resolve, reject) => {
    const headers = await getHeaders(multipart);
    process.env.NODE_ENV == "development"
      ? resolve(
          axios.create({
            timeout: 60000,
            headers: headers,
            baseURL: "https://localhost:7079",
          })
        )
      : resolve(
          axios.create({
            timeout: 60000,
            headers: headers,
          })
        );
  });
}

async function getHeaders(multipart?: string) {
  const headers = {
    Accept: "application/json",
    "Content-Type":
      multipart ||
      (IS_JSON ? "application/json" : "application/x-www-form-urlencoded"),
  };
  const token = db.get(JWT_TOKEN_KEY);

  if (token) {
    Object.assign(headers, {
      Authorization: `Bearer ${token.replace(/['"]+/g, "")}`,
    });
  }
  return headers;
}

function getBody(params: any) {
  return IS_JSON ? params : util.transformRequest(params);
}
async function get(path: string): Promise<any> {
  const axiosInstance = await getInstance();
  return axiosInstance
    .get(path)
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function postExternal(
  path: string,
  data?: any,
  multipart?: any
): Promise<any> {
  const axiosInstance = await getInstance(multipart);
  return axiosInstance
    .post(path, data)
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function post(path: string, data?: any, multipart?: any): Promise<any> {
  const axiosInstance = await getInstance(multipart);
  return axiosInstance
    .post(path, getBody(data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function put(path: string, data?: any): Promise<any> {
  const axiosInstance = await getInstance();
  return axiosInstance
    .put(path, getBody(data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function patch(path: string, data?: any): Promise<any> {
  const axiosInstance = await getInstance();
  return axiosInstance
    .patch(path, getBody(data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function remove(path: string, data?: any): Promise<any> {
  const axiosInstance = await getInstance();
  return axiosInstance
    .delete(path, getBody(data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

function handleResponse(res: AxiosResponse) {
  if (res.config.data) {
    const body = util.toString(res.config.data);
    // console.log("Request Body data ==> " + body, "info");
  }

  const data = util.toString(res.data);
  // console.log(
  //   "Request response data ==> " +
  //     (data && data.length < 100 ? data : data.substring(0, 100)),
  //   "info"
  // );

  return ensureAuth(res.data);
}

function ensureAuth(data: any) {
  if (typeof data === "string") {
    let parts = data.split("|");
    if (
      parts.length > 2 &&
      (Number(parts[1]) === 401 || Number(parts[1]) === 403)
    ) {
      db.clear();
      util.reload();
    }
  }
  return data;
}

const handleError = (err: any) => {
  let data = null;
  let status = 500;
  let message =
    "Une érreur est survenu lors de l'opération. Merci de reéssayer !";

  // console.log("<== Request Error ==> ");

  if (err && err.response) {
    // console.log(err.response.data);
    // console.log(err.response.status);
    // console.log(err.response.config);
    data = err.response.data;
    status = err.response.status;
    if (err && err.response && err.response.data && err.response.data.message) {
      message = err.response.data.message;
    } else if (err && err.response && err.response.message) {
      message = err.response.message;
    }
  } else if (err && err.request) {
    // console.log(err.request);
  } else {
    // console.log(err.message);
  }
  // console.log("<== End request Error ==> ");
  // console.log("==> status " + status);

  return { success: false, status, data, message };
};
