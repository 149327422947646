import { immerable } from "immer";
import { cloneDeep } from "lodash";
import { appConstants } from "../../../_constants";
import { Question } from "../../../_entities";
import { IFieldUpdatePayload } from "../../../_interfaces";
import { Field } from "../../field";
import { FormModel } from "../../form";
import { ValidatorFormModel } from "./validator";

export class QuestionFormModel extends FormModel {
  [immerable] = true;

  opened: boolean = false;
  removed: boolean = false;
  label: Field;
  required: Field;
  responseType: Field;
  description: Field;
  responses: Field;
  validator: ValidatorFormModel;

  question: Question;

  constructor(options?: { [key: string]: any }) {
    super();

    this.question = options?.question || new Question();
    this.opened = options?.opened || false;
    this.data = options;
    this.initFields();
  }

  public get needValidator(): boolean {
    return this.responseType.value === "OPEN_QUESTION";
  }

  public get needResponses(): boolean {
    return (
      ["BAROMETRE", "OPEN_QUESTION", "OPEN_QUESTION_BIG"].indexOf(
        this.responseType.value
      ) === -1
    );
  }

  public get typeResponseLayout(): string {
    if (this.responseType.value === "OPEN_QUESTION_BIG") {
      return "col-12";
    }
    return !this.needValidator && !this.needResponses ? "col-12" : "col-md-6";
  }

  initFields() {
    this.label = new Field({
      layout: "col-md-6",
      name: "label",
      label: "Intitulé",
      required: true,
      placeHolder: "Quel est votre question ?",
      helpText:
        "Titre que vous donné à votre question (visible du consommateur)",
    });
    this.required = new Field({
      layout: "col-md-6 padding-7px",
      name: "required",
      label: "Cette question est-elle obligatoire?",
      value: "true",
      type: "select",
      helpText: "Est-ce que le client est obligé de répondre à cette question",
      options: [
        { label: "Oui", value: "true" },
        { label: "Non", value: "false" },
      ],
    });
    this.description = new Field({
      name: "description",
      type: "richtext",
      label: "Description",
      helpText: "Description de la question (visible du consommateur)",
    });
    this.responseType = new Field({
      layout:
        this.question?.responseType?.code === "OPEN_QUESTION_BIG" ||
          this.question?.responseType?.code === "BAROMETRE"
          ? "col-12"
          : "col-md-6",
      name: "responseType",
      type: "select",
      label: "Type de reponse",
      value: "OPEN_QUESTION",
      helpText: "Comment le client sera ammené à repondre à cette question",
      options: appConstants.responseTypes,
    });
    this.responses = new Field({
      layout: "col-md-6",
      name: "responses",
      label: "Reponses possibles",
      helpText: "La liste des réponses que le client pourra choisir",
      type: "tag",
      value: [],
    });
    this.validator = new ValidatorFormModel({
      validator: this.question.validator,
    });

    this.populateFields();
    this.responseType.layout = this.typeResponseLayout;
  }

  getBody(index: number) {
    return {
      id: this.question.id || 0,
      label: this.label.value,
      description: this.description.value,
      required: this.required.value,
      responseType: this.responseType.value,
      responses: this.responses.value,
      validator: this.validator.getBody(),
      position: index + 1,
    };
  }

  populateFields() {
    if (!this.question.id) return;
    this.label.value = this.question.label;
    this.required.value = this.question.required;
    this.description.value = this.question.description;
    this.responseType.value = this.question.responseType.code;
    this.responses.value = this.question.responses;
  }
  updateFieldValue(payload: IFieldUpdatePayload) {
    const { key, value } = payload;
    this[key].value = value;
    this.responseType.layout = this.typeResponseLayout;
  }

  public removeQuestion() {
    return this.remove("/surveys/questions/" + this.question.id);
  }

  public clone() {
    return cloneDeep(this);
  }
}
