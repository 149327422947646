import { immerable } from "immer";
import { DASHBORD_INIT } from "../_constants";
import { Menu } from "../_entities";
import { util } from "../_helpers";
import { IMenu } from "../_interfaces";
import { RootModel } from "./root";

export class UiModel extends RootModel {
  [immerable] = true;

  private static _instance: UiModel | undefined;
  private static _loading = false;

  private _menus: IMenu[] = [];

  constructor() {
    if (UiModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use UiModel.getInstance() instead of new."
      );
    }
    super();
    UiModel._instance = this;
  }

  public get menus(): IMenu[] {
    return this._menus;
  }

  public static getInstance(): UiModel {
    if (!UiModel._instance) {
      return new UiModel();
    }
    return UiModel._instance;
  }

  public updateMenus(menus: IMenu[]) {
    while (this._menus.length) this._menus.pop();
    menus.forEach((m) => this._menus.push(m));
  }

  public reset(): UiModel {
    UiModel._instance = undefined;
    return UiModel.getInstance();
  }

  async initUi(): Promise<Menu[]> {
    if (UiModel._loading) return [];
    UiModel._loading = true;
    util.showLoading(DASHBORD_INIT);
    const res = await this.get("/users/session");
    if (res.status === "success") {
      if (res && res.data && Array.isArray(res.data.menus)) {
        util.hideLoading();
        return res.data.menus
          .filter((m) => m.active)
          .map((element) => Menu.fromJson(element));
      }
    }
    UiModel._loading = false;
    util.hideLoading();
    return [];
  }
}
