import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models/business/survey/form";
import { replaceForm, RootState } from "../../../../_reducers";
import { InputField } from "../../../shared";

declare var $: any;

export const SurveyStep4 = (props: StepProps) => {
  const { setStep } = props;
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel>(props.form);

  const next = () => {
    setStep(5);
    dispatch(replaceForm(form));
  };

  const onChange = (key: string, value: any) => {
    form.updateFieldValue({ key, value });
    setForm(form.clone());
    dispatch(replaceForm(form));
  };
  useEffect(() => {
    const m = model.page.model!.form as SurveyFormModel;
    setForm(m);
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }, [model.page.model!.form]);
  const {
    logo,
    bgHeader,
    textColorHeader,
    header,
    bgColor,
    textColor,
    buttonColor,
    thankYouText,
    bgSurvey,
    barometreColor,
    barometreSize,
    linkColor,
    bgFooter,
    textColorFooter,
    footer
  } = form;
  return (
    <div className="step-pane active p-3">
      <form className="form" role="form">
        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Entête</b></h3>
          </div>
        </div>
        <div className="row description-full-screen">
          <InputField field={logo} onChange={onChange} form={form} />
          <InputField field={bgHeader} onChange={onChange} />
          <InputField field={textColorHeader} onChange={onChange} />
          <InputField field={header} onChange={onChange} />
        </div>

        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Corps</b></h3>
          </div>
        </div>
        <div className="row description-full-screen">
          <InputField field={bgColor} onChange={onChange} />
          <InputField field={bgSurvey} onChange={onChange} />
          <InputField field={textColor} onChange={onChange} />    
          <InputField field={linkColor} onChange={onChange} />
          <InputField field={buttonColor} onChange={onChange} />
          <InputField field={barometreColor} onChange={onChange} />
          <InputField field={barometreSize} onChange={onChange} />
          <InputField field={thankYouText} onChange={onChange} />
        </div>

        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Pied de page</b></h3>
          </div>
        </div>
        <div className="row description-full-screen">
          <InputField field={bgFooter} onChange={onChange} />
          <InputField field={textColorFooter} onChange={onChange} />
          <InputField field={footer} onChange={onChange} />
        </div>

      </form>
      <hr />
      <a onClick={() => setStep(3)} className="btn info btn-next mr-2">
        <i className="fa fa-arrow-left"></i> Précédent
      </a>
      <a onClick={() => next()} className="btn info btn-next">
        <i className="fa fa-arrow-right"></i> Confirmer l'enregistrement !
      </a>
    </div>
  );
};
