import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { db, util } from "../../../_helpers";
import { SurveyModel } from "../../../_models";
import { replaceModel, RootState } from "../../../_reducers";
import { SurveyTabs } from "./tabs";

export * from "./new";
export * from "./edit";
export * from "./show";

const locks: any = {};

export const AdminQuestionnaires = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<any>();
  const [tabs, setTabs] = useState<any[]>([]);
  const model = useSelector((state: RootState) => state.app);

  const handleEvent = async (data: any) => {
    if (locks[data.key]) return;
    locks[data.key] = true;
    const sModel = (model.page.model! as SurveyModel).clone();
    const res = await sModel.handleEvent({ ...data, navigate });
    replaceModel(sModel);
    setTimeout(() => {
      locks[data.key] = false;
    }, 400);
    return res;
  };

  useEffect(() => {
    const sModel = model.page.model! as SurveyModel;
    setTabs(sModel.table.getTabs({}));
    setTab(sModel.table.getActiveTab({}));
  }, []);

  if (!tab || !tabs.length) return <div />;

  return (
    <div className="widget">
      <div className="widget-header">
        <span className="widget-caption">Liste des questionnaires</span>
        <div className="widget-buttons">
          <a href="#" data-toggle="maximize">
            <i className="fa fa-expand"></i>
          </a>
          <a href="#" data-toggle="collapse">
            <i className="fa fa-minus"></i>
          </a>
        </div>
      </div>
      <div className="widget-body">
        {tabs.map((s, i) => (
          <button
            key={i}
            id={s.id}
            className="btn mr-2"
            onClick={() => {
              db.set("survey_active_tab", s.id);
              setTab(s);
            }}
          >
            {s.label} <span className="count"></span>
          </button>
        ))}
        <Link to="new" className="btn btn-primary mr-2">
          <i className="fa fa-plus" aria-hidden="true"></i>
          Nouveau questionnaire
        </Link>
        <div className="checkbox d-none" style={{ display: "inline" }}>
          <label>
            <input
              type="checkbox"
              id="displayAuto"
              name="displayAuto"
              value="1"
            />
            <span className="text">Afficher les questionnaires d'Avomark</span>
          </label>
        </div>
        <div className="pull-right">
          <a className="btn btn-info" onClick={() => util.reload()}>
            <i className="glyphicon glyphicon-refresh" aria-hidden="true"></i>{" "}
            Rafraichir
          </a>
        </div>
        <hr />
        <SurveyTabs tab={Object.assign(tab, { handleEvent })} />
      </div>
    </div>
  );
};
