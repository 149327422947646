export const AdminDashboard = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-5">
          <div className="widget">
            <div className="widget-body no-padding">
              <div className="carteSaisieParAn-container">
                <div
                  id="carteSaisieParAn"
                  style={{
                    minWidth: "310px",
                    height: "400px",
                    margin: "0px auto",
                    overflow: "hidden",
                  }}
                  data-highcharts-chart="1"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="widget">
            <div className="widget-body no-padding">
              <div className="mouvementCaParAn-container">
                <div
                  id="mouvementCaParAn"
                  style={{
                    minWidth: "310px",
                    height: "400px",
                    margin: "0px auto",
                    overflow: "hidden",
                  }}
                  data-highcharts-chart="3"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="widget">
            <div className="widget-body no-padding">
              <div className="graph1-container">
                <div
                  id="graph1"
                  data-highcharts-chart="0"
                  style={{ overflow: "hidden" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="widget">
            <div className="widget-body no-padding">
              <div className="graph2-container">
                <div
                  id="graph2"
                  data-highcharts-chart="2"
                  style={{ overflow: "hidden" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <em className="legend-data-date">Données du 10/06/2022</em>
        </div>
      </div>
    </>
  );
};
