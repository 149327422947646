export const localErrors = [
  {
    key: "SURVEY_TITLE_REQUIRED",
    value:
      "Pour créer un questionnaire, vous devez au moins renseigner un titre",
  },
  {
    key: "SURVEY_TITLE_SHORT",
    value:
      "Le titre de votre questionnaire est trop court. Au moins 05 caractères requis.",
  },
  {
    key: "SURVEY_TITLE_LONG",
    value:
      "Le titre de votre questionnaire est trop long. Le nombre de caractères maximum est de 255.",
  },
  {
    key: "INVALID_FORMAT_URL",
    value: "Le format de l'url est invalide.",
  },
  {
    key: "EMPTY_URL",
    value: "L'Url est requis.",
  },
  {
    key: "REQUIRED_INTITULE",
    value: ""
    //value: "L'intitulé est requis",
  },
  {
    key: "REQUIRED_DESCRIPTION",
    value: "La description  est requise",
  },
];
