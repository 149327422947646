import { immerable } from "immer";
import { DisplayModel } from "../display";
import { FormModel } from "../form";
import { TableModel } from "../table";

export abstract class BusinessModel {
  [immerable] = true;

  table: TableModel;
  form: FormModel;
  display: DisplayModel;
}
