export const CellActions = (props: any) => {
  const {
    options: { actions },
  } = props;

  const items = typeof actions === "function" ? actions(props) : actions;
  const primaryAction =
    typeof items === "object" ? items.primaryAction : undefined;
  const secondaryActions =
    typeof items === "object" ? items.secondaryActions : [];
  return (
    <>
      {primaryAction && (
        <a {...primaryAction.attributtes}>
          <i className="fa fa-eye" aria-hidden="true"></i> {primaryAction.label}
        </a>
      )}
      {Array.isArray(secondaryActions) && secondaryActions.length > 0 && (
        <div className="btn-group ml-2">
          <a className="btn btn-default dropdown-toggle" data-toggle="dropdown" href="/#">
            Actions
          </a>
          <a
            className="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            href="/#"
            aria-expanded="false"
          >
            <i className="fa fa-angle-down"></i>
          </a>
          <ul className="dropdown-menu secondary-actions-menu">
            {secondaryActions.map((a, i) => {
              return (
                <li key={i}>
                  <a {...a.attributtes}>{a.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
