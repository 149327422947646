import { immerable } from "immer";
import { AuthModel } from "./auth";
import { RootModel } from "./root";
import { cloneDeep } from "lodash";
import { UiModel } from "./ui";
import { PageModel } from "./page";

export class BaseModel extends RootModel {
  [immerable] = true;

  private static _instance: BaseModel | undefined;

  auth: AuthModel;
  ui: UiModel;
  page: PageModel;

  constructor() {
    if (BaseModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use BaseModel.getInstance() instead of new."
      );
    }
    super();
    this.auth = AuthModel.getInstance();
    this.ui = UiModel.getInstance();
    this.page = PageModel.getInstance();
    BaseModel._instance = this;
  }

  public static getInstance(): BaseModel {
    if (!BaseModel._instance) {
      return new BaseModel();
    }
    return BaseModel._instance;
  }

  public clone() {
    return cloneDeep(this);
  }

  public reset(): BaseModel {
    BaseModel._instance = undefined;
    this.auth.reset();
    return BaseModel.getInstance();
  }
}
