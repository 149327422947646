import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models/business/survey/form";
import { QuestionFormModel } from "../../../../_models/business/survey/question";
import { replaceForm, RootState } from "../../../../_reducers";
import { Question2 } from "../question";
import { DnD } from "./DnD";

export const SurveyStep2 = (props: StepProps) => {
  const { setStep } = props;
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel>(props.form);

  const onChange = (index: number, question: QuestionFormModel) => {
    // console.log(question);
    const m = model.page.model!.form as SurveyFormModel;
    m.updateQuestion(index, question);
    setForm(m.clone());
    dispatch(replaceForm(m));
  };
  useEffect(() => {
    const m = model.page.model!.form as SurveyFormModel;
    setForm(m);
  }, [model.page.model!.form]);

  const getQuestions = () => {
    const m = model.page.model!.form as SurveyFormModel;
    return m.questions.map((q, i) => {
      q.opened = true;
      return {
        id: i + 1,
        title: "Question #" + (i + 1),
        question: q,
        content: (
          <Question2
            question={q}
            onChange={(qu: QuestionFormModel) => onChange(i, qu)}
          />
        ),
      };
    });
  };

  const [questions] = useState<any>(getQuestions());

  return (
    <div className="step-pane active">
      <DnD items={questions} />

      <hr />
      <a onClick={() => setStep(1)} className="btn info btn-next mr-2">
        <i className="fa fa-arrow-left"></i> Précédent
      </a>
      <a onClick={() => setStep(3)} className="btn info btn-next">
        <i className="fa fa-arrow-right"></i> Suivant
      </a>
    </div>
  );
};
