import { ValidatorFormModel } from "../../../../_models/business/survey/validator";
import { InputField } from "../../../shared";

export const QuestionValidator = ({
  validator,
  onChange,
}: {
  validator: ValidatorFormModel;
  onChange: any;
}) => {
  const { type, min, max } = validator;

  const update = (key: string, value: any) => {
    validator.updateFieldValue({ key, value });
    onChange(validator.clone());
  };
  return (
    <div className="col-md-6">
      <div className="row">
        <InputField field={type} onChange={update} />
        {validator.type.value==="TEXT" &&
        
        (
          <>
          <InputField field={min} onChange={update} />
          <InputField field={max} onChange={update} />
          </>
        )}
        
        
      </div>
    </div>
  );
};
