import { util } from "../../../_helpers";
import { Field } from "../../../_models";

export const InputSelect = ({
  field,
  onChange,
}: {
  field: Field;
  onChange: any;
}) => {
  const getPreview = () => {
    if (field.options.length) {
      const i = field.options.findIndex((o) => o.value === field.value);
      if (i > -1) return field.options[i].previewLink;
    }
  };
  const preview = getPreview();

  return (
    <div {...{ className: "form-group " + field.layout }}>
      <label htmlFor={field.name}>
        {field.label}
        {field.required && (
          <i className="required-label" data-placement="right">
            *
          </i>
        )}
        <i
          className="fa fa-question-circle"
          style={{ cursor: "pointer" }}
          data-toggle="tooltip"
          data-placement="right"
          title=""
          data-original-title={field.helpText}
        ></i>
      </label>
      <select
        value={field.value}
        onChange={(e) => onChange(field.name, e.target.value)}
        className={field.classNames}
      >
        {field.options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      {preview && (
        <div style={{ paddingTop: "10px" }}>
          <img
            style={{
              maxHeight: "135px",
              border: "1px solid #ccc",
              objectFit: "contain",
              width: "70%",
            }}
            src={preview}
          />
        </div>
      )}
      {field.error && (
        <span style={{ color: "red" }}>{util.getErrorByKey(field.error)}</span>
      )}
    </div>
  );
};
