import { useRef } from "react";
import { TableContent } from "./Content";

export * from "./Cell";

export function Table(props: any) {
  const ref = useRef(null);

  return <TableContent {...props} tabRef={ref} />;
}
