import { Table } from "../../../shared";

export const Draft = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_draft">
      <p className="alert alert-danger">
        <strong>Attention !</strong> Tout brouillon inchangé depuis plus de 30
        jours sera supprimé
      </p>
      <h1>Brouillon(s)</h1>
      <Table {...tab} />
    </div>
  );
};
