import { renderKeys } from "../../../../_constants";
import { util } from "../../../../_helpers";
import { CellActions } from "./Actions";
import { CellActive } from "./Active";
import { CellId } from "./Id";
import { CellSurveyType } from "./SurveyType";

const { RENDER_ID, RENDER_ACTIONS, RENDER_ACTIVE, RENDER_SURVEY_TYPE } =
  renderKeys;

export const CellData = (props: any) => {
  const {
    options: { renderKey, transformKey },
    data,
  } = props;

  let text = data;
  if (transformKey) text = util.transformValue(transformKey, data);
  let config = { ...props, data: text };

  if (renderKey === RENDER_ID) return <CellId {...config} />;
  if (renderKey === RENDER_ACTIVE) return <CellActive {...config} />;
  if (renderKey === RENDER_ACTIONS) return <CellActions {...config} />;
  if (renderKey === RENDER_SURVEY_TYPE) return <CellSurveyType {...config} />;
  return <span>{text}</span>;
};
