import { useEffect, useState } from "react";
import { eventKeys } from "../../../_constants";
import { tableRenderer, util } from "../../../_helpers";

declare var $: any;

const { TABLE_BTN_CLICK, TABLE_DATA_CHANGE } = eventKeys;

export function TableContent(props: any) {
  const { tabRef, cls, handleEvent } = props;
  const id = props?.id || "admin_table";
  const styles = { id, className: cls || "" };
  const [table, setTable] = useState<any>();

  const onTableInit = () => {
    $(tabRef.current).find("thead").addClass("bordered-primary");
  };

  useEffect(() => {
    createDataTable(props, tabRef).then((datatable) => {
      onTableInit();
      const handleTableBtnClickEvent = async (data: any) => {
        if (handleEvent) {
          await handleEvent({ ...data, datatable });
        }
      };
      const handleTableReloadEvent = async () => {
        datatable.ajax.reload();
      };
      util.emitter.on(TABLE_BTN_CLICK, (data: any) => {
        handleTableBtnClickEvent(data);
      });
      util.emitter.on(TABLE_DATA_CHANGE, () => {
        handleTableReloadEvent();
      });
      //setTable(datatable);
    });

    return () => {
      destroyDataTable(table, tabRef);
      // util.emitter.off(TABLE_BTN_CLICK, handleTableBtnClickEvent);
      //util.emitter.off(TABLE_DATA_CHANGE, handleTableReloadEvent);
    };
  });

  return <table {...styles} ref={tabRef} className="table table-hover w-100" />;
}

const createDataTable = async (props: any, ref: any) => {
  let tab = ($(ref.current) as any).DataTable({
    ...tableRenderer.getOptions(props),
    ...(props.tableSettings || {}),
  });
  await refreshDataTableData(tab, props);

  return tab;
};

const destroyDataTable = (datatable: any, ref: any) => {
  datatable?.clear();
  datatable?.destroy();
  $(ref.current).empty();
};

const refreshDataTableData = async (datatable: any, props: any) => {
  const { data } = props;
  if (typeof data === "function") {
    const d = await data();
    datatable.clear();
    datatable.rows.add(d);
    datatable.draw();
    return;
  }
  if (!datatable || !data) {
    return;
  }
  datatable.clear();
  datatable.rows.add(data);
  datatable.draw();
};
