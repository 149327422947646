import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SurveySteps } from "./steps";
import { useDispatch, useSelector } from "react-redux";
import { SurveyFormModel } from "../../../_models/business/survey/form";
import { replaceForm, RootState } from "../../../_reducers";
import { appConstants } from "../../../_constants";
import { useInterval } from "usehooks-ts";
import { util } from "../../../_helpers";

export const AdminQuestionnairesForm = (props: any) => {
  const { title, subtitle, edit } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const uuid = search.get("uuid");
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel | undefined>();
  const [step, setStep] = useState<number>(edit ? 1 : 0);
  const [loaded, setLoaded] = useState(false);

  useInterval(async () => {
    if (step === 5) return;
    const m = model.page.model!.form as SurveyFormModel;
    //let test = m.validateQuestions();
    //if (m!.survey.uuid && test) {
    if (m!.survey.uuid) {
      util.showToast("Enregistrement du questionnaire en cours...", 3000);
      await m!.save(false, true);
    }
    dispatch(replaceForm(m));
  }, 30000);

  const onStep = (s: number) => {
    // if (s === 5 || step === 0 || step === 5) return;
    if (s === 5 || step === 0 || step === 5) {
      if(form?.survey.status === 'DRAFT'){
        setStep(s)
      }
      else {
        return;
      }
    };
    setStep(s);
  };
  const setStepAsync = async (s: number, data?: any) => {
    setStep(s);
  };

  useEffect(() => {
    const f = model.page.model!.form as SurveyFormModel;
    if (uuid !== f.survey.uuid) {
      f.init(uuid).then((m) => {
        if (!m.survey.isDraft && m.survey.id) {
          util.showError(
            "Erreur!",
            "Seuls les questionnaires avec le status brouillon peuvent être modifiés. Veuillez le passer au préalable à l'état brouillon"
          );
          navigate("..");
        } else {
          dispatch(replaceForm(m));

          setForm(m);
          if (uuid) setStep(1);
          setLoaded(true);
        }
      });
    } else {
      const m = model.page.model!.form as SurveyFormModel;
      setForm(m);
      setLoaded(true);
    }
  }, [uuid, model.page.model!.form]);

  const links = appConstants.surveyLinks;
  links[0].label = subtitle ?? links[0].label;

  return (
    <div className="widget">
      <div className="widget-header ">
        <span className="widget-caption">
          {title ?? "Ajouter un questionnaire"}
        </span>
        <div className="widget-buttons">
          <a href="#" data-toggle="maximize">
            <i className="fa fa-expand"></i>
          </a>
          <a href="#" data-toggle="collapse">
            <i className="fa fa-minus"></i>
          </a>
        </div>
      </div>
      <div className="widget-body">
        <div className="wizard">
          <ul className="steps">
            {links.map((l, i) => {
              let canAddChevron = i+1<links.length;
              return (
                <li
                  key={i}
                  onClick={() => onStep(l.id)}
                  className={l.indexes.indexOf(step) !== -1 ? "active" : ""}
                >
                  <span className="step">{l.id}</span> {l.label}
                  { canAddChevron && <span className="chevron"></span>}
                </li>
              );
            })}
          </ul>
          <div className="actions">
            <button
              onClick={() => util.goTo("/backoffice/questionnaires")}
              className="btn btn-default btn-sm shiny btn-prev"
            >
              <i className="fa fa-angle-left"></i>Retour aux questionnaires
            </button>
          </div>
        </div>
        {form && loaded && (
          <SurveySteps form={form} step={step} setStep={setStepAsync} />
        )}
      </div>
    </div>
  );
};
