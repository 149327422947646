import { StepProps } from "../../../../_interfaces";

export const SurveyStep4 = (props: StepProps) => {
  const { setStep, form } = props;

  return (
    <div className="step-pane active">
      <form>

        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Entête</b></h3>
          </div>
        </div>
        <div className="row">
          {form.survey.designHeader.map((item, i) => (
            <div key={i} className={"form-group " + item.layout}>
              <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {item.label}
              </label>
              <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Corps</b></h3>
          </div>
        </div>
        <div className="row">
          {form.survey.designQuestion.map((item, i) => (
            <div key={i} className={"form-group " + item.layout}>
              <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {item.label}
              </label>
              <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
            </div>
          ))}
        </div>
          
        <div className="row">
          <div className="col">
            <h3 className="section-title"><b>Pied de page</b></h3>
          </div>
        </div>
        <div className="row">
          {form.survey.designFooter.map((item, i) => (
            <div key={i} className={"form-group " + item.layout}>
              <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {item.label}
              </label>
              <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
            </div>
          ))}
        </div>
        <hr />
        <a onClick={() => setStep(3)} className="btn info btn-next mr-2">
          <i className="fa fa-arrow-left"></i> Précédent
        </a>
        <a className="btn info btn-next" onClick={() => setStep(5)}>
          <i className="fa fa-arrow-right"></i> Suivant
        </a>
      </form>
    </div>
  );
};
