import { immerable } from "immer";
import { util } from "../_helpers";
import { BusinessModel, SurveyModel } from "./business";
import { RootModel } from "./root";

export type IPageConfig = {
  path: string;
  pageTitle: string;
  model?: BusinessModel;
};

export class PageModel extends RootModel {
  [immerable] = true;

  private static _instance: PageModel | undefined;

  private static _configs: IPageConfig[] = [
    {
      path: "/backoffice",
      pageTitle: "Accueil - Tableau de bord",
    },
    {
      path: "/backoffice/questionnaires",
      pageTitle: "Gestion des questionnaires",
      model: SurveyModel.getInstance(),
    },
    {
      path: "/backoffice/questionnaires/new",
      pageTitle: "Ajouter un questionnaire",
      model: SurveyModel.getInstance(),
    },
    {
      path: "/backoffice/questionnaires/edit",
      pageTitle: "Modifier un questionnaire",
      model: SurveyModel.getInstance(),
    },
    {
      path: "/backoffice/questionnaires/show",
      pageTitle: "Détails d'un questionnaire",
      model: SurveyModel.getInstance(),
    },
  ];

  constructor() {
    if (PageModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use PageModel.getInstance() instead of new."
      );
    }
    super();
    PageModel._instance = this;
  }

  public get path(): string {
    return util.path();
  }

  public get model(): BusinessModel | undefined {
    const config = PageModel._configs.find((p) => p.path === this.path);
    if (config && config.model) {
      return config.model;
    }
  }

  public static getInstance(): PageModel {
    if (!PageModel._instance) {
      return new PageModel();
    }
    return PageModel._instance;
  }

  public reset(): PageModel {
    PageModel._instance = undefined;
    return PageModel.getInstance();
  }
}
