export const CellActive = (props: any) => {
  const { data, row } = props;
  const cls = !data ? "fa fa-times fa-lg" : "fa fa-check fa-lg";
  const cls2 = !data ? "text-danger" : "text-success";

  return (
    <a data-id={row.data.uuid} className={cls2 + " inactive"}>
      <i className={cls}></i>
    </a>
  );
};
