import { configureStore } from "@reduxjs/toolkit";
import { appReducer } from "../_reducers";

export const store = configureStore({
  reducer: {
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispacth = typeof store.dispatch;
