import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { util } from "../../../../_helpers";
import { StepProps } from "../../../../_interfaces";
import { SurveyFormModel } from "../../../../_models/business/survey/form";
import { replaceForm, RootState } from "../../../../_reducers";
import { InputField } from "../../../shared";

declare var $: any;

export const SurveyStep3 = (props: StepProps) => {
  const { setStep } = props;
  const dispatch = useDispatch();
  const model = useSelector((state: RootState) => state.app);
  const [form, setForm] = useState<SurveyFormModel>(props.form);

  const next = () => {      

    let isUrlsValid = true;
    
    if(form.fidelityRegistrationUrl.value && !util.check_url_link(form.fidelityRegistrationUrl.value))
    {
      form.fidelityRegistrationUrl.error = "INVALID_FORMAT_URL";
      isUrlsValid = false;
    }
    else
    {
      form.fidelityRegistrationUrl.error = "";
    }

    if(!util.check_url_link(form.cguUrl.value))
    {
      form.cguUrl.error = "INVALID_FORMAT_URL";
      isUrlsValid = false;
    }
    else
    {
      form.rgpdUrl.error = "";
    }

    if(!util.check_url_link(form.rgpdUrl.value))
    {
      form.rgpdUrl.error = "INVALID_FORMAT_URL";
      isUrlsValid = false;
    }
    else
    {
      form.cguUrl.error = "";
    }
 

    setForm(form.clone());
    dispatch(replaceForm(form));

    if(isUrlsValid) 
    {
      setStep(4);
    }
    else
    {
      util.showError(
        "Erreur!",
        "Vous devez entrer des Urls valides avant de continuer"
      );
    }
  };

  const onChange = (key: string, value: any) => {
    form.updateFieldValue({ key, value });
    setForm(form.clone());
    dispatch(replaceForm(form));
  };

  useEffect(() => {
    const m = model.page.model!.form as SurveyFormModel;
    setForm(m);
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }, [model.page.model!.form]);

  const { fidelityRegistrationText, fidelityRegistrationUrl, cguUrl, rgpdUrl, unsubscribeText, cguText } =
    form;

  return (
    <div className="step-pane active p-3">
      <form className="form" role="form">
        <div className="row description-full-screen">
          <InputField field={fidelityRegistrationUrl} onChange={onChange} />
          <InputField field={fidelityRegistrationText} onChange={onChange} />
          <InputField field={cguUrl} onChange={onChange} />
          <InputField field={rgpdUrl} onChange={onChange} />
          {/* <InputField field={unsubscribeText} onChange={onChange} /> */}
          {/*<InputField field={cguText} onChange={onChange} />*/}
        </div>
      </form>
      <hr />
      <a onClick={() => setStep(2)} className="btn info btn-next mr-2">
        <i className="fa fa-arrow-left"></i> Précédent
      </a>
      <a onClick={() => next()} className="btn info btn-next">
        <i className="fa fa-arrow-right"></i> Suivant
      </a>
    </div>
  );
};
