import { Table } from "../../../shared";

export const Expired = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_sending">
      <h1>Expiré(s)</h1>
      <Table {...tab} />
    </div>
  );
};
