import { Field } from "../../../_models";
import { RichTextEditor } from "../RichTextEditor";

export const InputRichText = ({
  field,
  onChange,
}: {
  field: Field;
  onChange: any;
}) => {
  return (
    <div {...{ className: "form-group " + field.layout }}>
      <label htmlFor={field.name}>
        {field.label}
        {field.required && (
          <i className="required-label" data-placement="right">
            *
          </i>
        )}
        <i
          className="fa fa-question-circle"
          style={{ cursor: "pointer" }}
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title={field.helpText}
        ></i>
      </label>
      <RichTextEditor field={field} onChange={onChange} />
    </div>
  );
};
