import { immerable } from "immer";
import { User } from "../_entities";
import { util } from "../_helpers/util";
import { IApiResponse } from "../_interfaces";
import { RootModel } from "./root";

export class AuthModel extends RootModel {
  [immerable] = true;

  private static _instance: AuthModel | undefined;
  private static _loading = false;

  private _user: User;

  constructor() {
    if (AuthModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use AuthModel.getInstance() instead of new."
      );
    }
    super();
    this._user = User.fromSession();
    AuthModel._instance = this;
  }

  public get user(): User {
    return this._user;
  }

  public get isLoggedIn(): boolean {
    if (this._user.id) {
      return true;
    }
    return false;
  }

  public get isAdmin(): boolean {
    return this.user.role === "ROLE_ADMIN";
  }

  public get isSurveyList(): boolean {
    return util.path() === "/backoffice/questionnaires";
  }

  public static getInstance(): AuthModel {
    if (!AuthModel._instance) {
      return new AuthModel();
    }
    return AuthModel._instance;
  }

  public reset(): AuthModel {
    AuthModel._instance = undefined;
    return AuthModel.getInstance();
  }

  public async authenticate(
    user: User
  ): Promise<IApiResponse | any | undefined> {
    if (AuthModel._loading) return -1;
    AuthModel._loading = true;
    // console.log("++++ ,", user);
    const err = await user.validateAuth();
    if (!err) {
      try {
        const res = await this.post(user.toJsonAuthPayload());
        //const res = userData as IApiResponse;
        if (res.status === "success") {
          this._user.populate({ ...user, ...res.data });
          this._user.saveLocal();
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    }
    AuthModel._loading = false;
    return undefined;
  }

  public async logout() {
    if (AuthModel._loading) return;
    AuthModel._loading = true;
    this._user.clearLocal();
    AuthModel._loading = false;
    util.goTo("/");
  }
}
