import { Table } from "../../../shared";

export const Maquettes = ({ tab }) => {
  return (
    <div className="mailingdiv mailing_templateSetting">
      <h1>Maquettes</h1>
      <a href="#" className="btn btn-success mb-3">
        Ajouter une maquette
      </a>
      <Table {...tab} />
    </div>
  );
};
