import { useSelector } from "react-redux";
import { db, util } from "../../../_helpers";
import { RootState } from "../../../_reducers";

export const Navbar = () => {
  const model = useSelector((state: RootState) => state.app);

  const params = db.get("params");

  let logo = "default.png";
  let shopname = "N.C";
  let fullname = "N.C N.C";
  if (typeof params === "object") {
    logo = params.logo;
    shopname = params.shopname;
    fullname = params.fullname;
  }

  const configs = util.getConfigs();

  const onLogout = async (e: any) => {
    e.preventDefault();
    await model.auth.logout();
    document.location = configs.f2Services;
  };

  return (
    <div className="navbar">
      <div className="navbar-inner navbar-purple">
        <div className="navbar-container">
          <div
            className="navbar-servicemenu pull-left"
            id="header-top-menuservice"
          >
            <a href={configs.f2Services}>
              <i
                style={{ display: "inlineBlock" }}
                className="collapse-icon fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </a>
            {/* <i
              style={{ display: "inlineBlock" }}
              className="collapse-icon fa fa-th"
              aria-hidden="true"
            ></i> */}
            {/* <div id="menuservice">
              <div className="col-sm-4">
                <div className="service-avomark-block">
                  <a href="/Statistics">
                    <img src="/Public/img/service/service-2.jpg" />
                    <h5>Requêtage &amp; Statistiques</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="service-avomark-block">
                  <a href="https://caissent360.avomark.fr/Auth?login=abyst21&amp;password=Abyst21%&amp;entity=5042">
                    <img src="/Public/img/service/service-1.jpg" />
                    <h5>FidOnline +</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="service-avomark-block">
                  <a href="https://extranet.avomark.fr/index.php?module=v2&amp;etat=login&amp;action=autologin&amp;login=abyster2&amp;pwd=aby5126mer2">
                    <img src="/Public/img/service/service-37.jpg" />
                    <h5>Caisse</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="service-avomark-block">
                  <a href="/Statistics/MarketingCampaign">
                    <img src="/Public/img/service/service-73.png" />
                    <h5>Marketing Direct +</h5>
                  </a>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="service-avomark-block">
                  <a href="https://prod.ui.survey.avomark.fr/Auth?login=abyster2&amp;password=aby5126mer2&amp;entity=6716">
                    <img src="/Public/img/service/service-130.jpg" />
                    <h5>Questionnaires</h5>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <div className="navbar-header pull-left">
            <a href={configs.f2Services} className="navbar-brand">
              <small>
                <img src="/Public/img/avomark_services.png" />
              </small>
            </a>
          </div>

          <div className="navbar-header pull-left">
            <span
              style={{
                color: "white",
                padding: "10px 15px",
                display: "block",
                fontSize: "16px",
              }}
            >
              Questionnaires
            </span>
          </div>
          <div className="navbar-header pull-right">
            <div className="navbar-account">
              <ul className="account-area">
                <li>
                  <a
                    className="header-contact-phone dropdown-toggle"
                    title="Contactez nous"
                    href="#"
                    aria-expanded="true"
                  >
                    <i className="icon fa fa-phone"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-toggle"
                    title="Envoyer un e-mail"
                    href="mailto:support@avomark.fr"
                    aria-expanded="true"
                  >
                    <i className="icon fa fa-at"></i>
                  </a>
                </li>
                {model.auth.user.id && (
                  <li>
                    <a
                      className="login-area dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <div className="avatar" title="View your public profile">
                        <img src={`/Public/media/group/logo/${logo}`} />
                      </div>
                      <section>
                        <h2>
                          <span className="profile">
                            <span>Utilisateur : {fullname}</span>
                          </span>
                        </h2>
                      </section>
                    </a>
                    <ul className="pull-right dropdown-menu dropdown-arrow dropdown-login-area">
                      <li className="email">
                        <a>{shopname}</a>
                      </li>

                      <li>
                        <div className="avatar-area">
                          <img
                            src={`/Public/media/group/logo/${logo}`}
                            className="avatar"
                          />
                        </div>
                      </li>
                      <li className="dropdown-footer">
                        <a href="" onClick={(e) => onLogout(e)}>
                          Déconnexion
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
