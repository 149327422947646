import { util } from "../../../../_helpers";
import { StepProps } from "../../../../_interfaces";

export const SurveyStep1 = (props: StepProps) => {
  const { setStep, form } = props;
  // console.log(form);
  const configs = util.getConfigs();
  let code = "";
  let qrcode = "";
  if (Array.isArray(form?.survey?.metas)) {
    const m1 = form.survey.metas.find(
      (n) =>
        n.code ===
        configs.shortCodeKey
          .split("_")
          .map((c) => c.toLowerCase())
          .map((c, i) => {
            if (i !== 0) {
              return c.charAt(0).toUpperCase() + c.slice(1);
            }
            return c;
          })
          .join("")
    );
    const m = form.survey.metas.find(
      (n) =>
        n.code ===
        configs.qrCodeKey
          .split("_")
          .map((c) => c.toLowerCase())
          .map((c, i) => {
            if (i !== 0) {
              return c.charAt(0).toUpperCase() + c.slice(1);
            }
            return c;
          })
          .join("")
    );
    if (m) {
      qrcode = m.value;
      const img = `<img id="base64ImageForDisplay" alt="Preview Image" src="data:image/png;base64,${qrcode}">`;
      qrcode = `<figure style="display: flex;justify-content: left;">${img}</figure>`;
    }
    if (m1) {
      code = configs.qrcodeLink + m1.value;
    }
  }

  return (
    <div className="step-pane active">
      <div className="row">
        <div className="col-md-6">
          {form.survey.infos.map((item, i) => (
            <div key={i} className={item.layout}>
              <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                {item.label}
              </label>
              <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
            </div>
          ))}
        </div>
        <div className="col-md-6">
          <div className="col-12">
            <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              Lien court du questionnaire
            </label>
            {form.survey.isDraft ? (

              <p>Ce questionnaire n'est pas encore en ligne</p>
              
            ) : ( code ?
              <p>
                <a href={code} target="_blank">
                  {code}
                </a>
              </p> : <p>-</p>
            )}
          </div>
          <div className="col-12">
            <label style={{ textTransform: "uppercase", fontWeight: "bold" }}>
              QR code du questionnaire
            </label>
            {form.survey.isDraft ? ( <p>Ce questionnaire n'est pas encore en ligne</p> ) : 
            ( qrcode ?
              <div dangerouslySetInnerHTML={{ __html: qrcode }}></div> : <p>-</p>
            )
            }
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <hr />
          <a className="btn info btn-next" onClick={() => setStep(2)}>
            <i className="fa fa-arrow-right"></i> Suivant
          </a>
        </div>
      </div>
    </div>
  );
};
